
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import  DialogContent  from '@material-ui/core/DialogContent';
import  DialogActions  from '@material-ui/core/DialogActions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import axios from 'axios';


const useStyles = makeStyles((theme) =>({
   

  container:{ 
    marginTop:theme.spacing(-2) ,
    textAlign:"center", 
    justifyContent:"right"
  },
  title:{
      fontWeight :30,
      fontStyle:"bold",
      textAlign :"center"
      
  },
  button:{
      alignItems: "center",
      width:"50%",
  }
}));

function ConfirmDialogBox(props) {
  const classes = useStyles();
  const history = useHistory();
  const title ="Are You Ready?"
  const BASE_URL = `${process.env.REACT_APP_API_URL}`;
  const { onClose, selectedValue, open, examObject,examinationType } = props;
  
const handleClose = () => {
    onClose(selectedValue, false);
  };

  const handleSubmit = () =>{
    history.push(
      {
          pathname: '/practice/flashScreen'
      });
      setTimeout(() => {
        updateExamParticipation()
      }, 5000);
   
  }

  const disableBatch = () => {
    let studentBatchScheduleObject = {
        'studentBatchScheduleId': examObject.studentBatchScheduleId,
        'batch': {
            'batchId': examObject.batchId
        },
        'exam': {
            'examId': examObject.exam.examId
        },
        'student': {
            'studentId': examObject.studentId
        },
        'active': true
    }

    axios.post(BASE_URL + `/student/batch`, studentBatchScheduleObject,
        {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem("authorization")
            }
        }).then((response) => {
        });

}

  const updateExamParticipation =()=>{
    if(!examObject.isMokeTest || examObject.exam.recordPracticeScores){
      // disableBatch();
    let examParticipation ={
        // 'studentBatchSchedule':{
          'studentBatchScheduleId': examObject.studentBatchScheduleId,
       //  },
          'examStatus':'started',
          'examParticipationId':0,
          'createdBy':examObject.studentName,
          'lastUpdatedBy':examObject.studentName,
          'examinationType':examinationType,
          'active':'true',
          'questionPaperId':'0',
          'marksScored':0
          //send the prodigy variable for backend
          // to get prodigy question papers 
        }    
  
     axios.post(BASE_URL + `/examinationParticipation/examParticipation/save`,examParticipation,
         {
             headers: {
                 authorization: 'Bearer ' + localStorage.getItem("authorization")
             }
         }).then((response) => {
             examObject.examParticipationId = response.data.examParticipationId;
             examObject.startTime=response.data.startTime;
             history.push(
              {
              pathname: '/student/portal/test2',
              state: {examObject: examObject,
                      examinationType:examinationType}
            }) 
         }).catch(function (error) {
          onClose(selectedValue, true);
        });
        }else{
          history.push(
            {
            pathname: '/student/portal/test2',
            state: {examObject: examObject,
                    examinationType:examinationType}
          }) 

        }
 }

  return (
    <Dialog  fullWidth={true}  maxWidth ={'xs'} onClose={handleClose} aria-labelledby="confirm-dialog-box" open={open} 
    disableBackdropClick={true} >
      <DialogTitle className={classes.title}>{title}</DialogTitle>
      <DialogContent className ={classes.container}>
        <p style={{ whiteSpace: "pre-line" }}>{selectedValue}  </p>
       </DialogContent>
       <DialogActions >
       <Button  className={classes.button} color = "secondary" variant="contained" size={"medium"}  onClick={handleClose} style={{alignContent:"center"}}
                 >Cancel</Button>

       <Button className={classes.button} color = "primary" variant="contained" size={"medium"}  onClick={() => 
       handleSubmit()} style={{alignContent:"center"}}
                 >Start</Button>
       </DialogActions>
    </Dialog>

  );
}

ConfirmDialogBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  examObject: PropTypes.object.isRequired,
};

export default ConfirmDialogBox;