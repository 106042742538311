import React, { Fragment, useContext, useState } from "react";
import clsx from "clsx";
import HeaderImage from "./sample-background.jpg";
import InstructionsImage from "./instructions.svg";
//import MockTestImage from "./mock-test.svg";
import ActualTestImage from "./actual-test.svg";
import MockTestImage from "./card_1.jpg";
import MockTestImageMobile from "./card_1_mobile.png";
import SummaryDashBoard from "./SummaryDashBoard";
import {
  makeStyles,
  CssBaseline,
  Grid,
  Paper,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
  Snackbar,
  Button,
  useTheme,
  useMediaQuery,
  Backdrop,
  CircularProgress,
  Tab,
  Box,
  Dialog, DialogContent, DialogActions, DialogTitle
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ConfirmDialogBox from "../Exam/testConfirmationDialog";
import axios from "axios";
import { useEffect } from "react";
import jwt_decode from "jwt-decode";
import Alert from "@material-ui/lab/Alert";
import RefreshIcon from "@material-ui/icons/Refresh";
import HistoryIcon from "@material-ui/icons/History";
import PracticeExamHistory from "../PracticeExamHistory";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { SaveAltSharp } from "@material-ui/icons";
import { saveAs } from "file-saver";
import { LoggedInUserDetails } from "../LoggedInUserDetails/LoggedInUserDetails";
import Tabs from "@material-ui/core/Tabs";
import PropTypes from "prop-types";
import Instruction from "./Instruction.jpg";
import InstructionMobile from "./InstructionMobile.jpg";
import boxback from "./box_1.png";
import boxwhite from "./box_1_white.png";
import { is } from "date-fns/locale";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1, 5, 0, 5),
  },
  paperBackground: {
    padding: theme.spacing(0),
    backgroundImage: `url(${HeaderImage})`,
  },
  card: {},
  media: {
    height: 348,
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  practiceScoreButtonMobile: {
    marginRight: "5%",
    width: "40%",
    fontSize: "12px",
    fontFamily: "FilsonSoftW03-Medium",
  },
  practiceScoreButton: {
    marginRight: "1%",
    fontFamily: "FilsonSoftW03-Medium",
  },
  starCountlogo: {
    marginLeft: "35%",
    marginTop: "1%",
  },
  refreshButtonMobile: {
    marginRight: "10%",
    fontSize: "12px",
    fontFamily: "FilsonSoftW03-Medium",
  },
  refreshButton: {
    marginRight: "5%",
    fontSize: "12px",
    fontFamily: "FilsonSoftW03-Medium",
  },
  dialogBoxHeight: {
    '& .MuiDialog-paperFullWidth': {
        height: 'auto',
        width: 'auto',
        overFlowY:'hidden'
    }
}
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,

  index: PropTypes.any.isRequired,

  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,

    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
function StudentDashboard(props) {
  const classes = useStyles();
  const history = useHistory();
  const dialogContent = {
    content: (
      <Fragment>
        <span>You are about to begin the test.</span>
        <br />
        <span> We wish you All The Best! </span>
        <br />
        <span>
          Click <strong> 'Start'</strong> to begin.
        </span>
      </Fragment>
    ),
  };
  const abortedDialogContent = {
    content: (
      <Fragment>
        <span>You Session Aborted Three times for the Day.Please Countinue Your Practice on tomorrow.</span>
      </Fragment>
    ),
  };
  const BASE_URL = `${process.env.REACT_APP_API_URL}`;

  let [open, setOpen] = React.useState(false);

  const [selectedValue, setSelectedValue] = React.useState("");
  const [abortDialog, setAbortDialog] = React.useState(false);


  const [state, setState] = React.useState({
    openAlert: false,
    vertical: "top",
    horizontal: "center",
    message: "",
    severity: "",
    alertMessage: "",
  });
  const { vertical, horizontal, openAlert, message, severity } = state;
  const handleAlertClose = () => {
    setState({ ...state, openAlert: false });
  };

  const onInstructionsClick = () => {
    history.push("/student/portal/instructions", {
      examination: props.location.state.examination,
    });
  };

  const onTestClick = (batchId) => {
    if(abortDialog==true){
     onAbortClick();
    }else{
      setOpen(!open);
      setSelectedValue(dialogContent.content);
    }
    
  };
  const onAbortClick = (batchId) => {
    setIsAbored(!isAborted);
  };

  const handleClose = (value, testTakenOnce) => {
    setOpen(!open);
    if (testTakenOnce) {
      setState({
        ...state,
        openAlert: true,
        message: "Test is started already!",
        severity: "error",
      });
    }
    setSelectedValue(value);
  };

  const handleAbortedClose = () => {
    setIsAbored(!isAborted);
  };

  const viewHistory = () => {
    setOpenViewHistory(true);
  };

  function closeViewHistory() {
    setOpenViewHistory(false);
  }

  const [loaded, setLoaded] = useState(true);
  let theme = useTheme();
  const [mockTestFlag, setMockTestFlag] = useState(false);
  const [examFlag, setExamFlag] = useState(false);
  const [mockObject, setMockObject] = useState([]);
  const [testObject, setTestObject] = useState("");
  const [isExam, setIsExam] = useState();
  const [isAborted, setIsAbored] = useState(false);

  const [studentInfo] = React.useState(
    jwt_decode(localStorage.getItem("authorization"))
  );
  const { loggedInUserInfo, setLoggedInUserInfo } =
    useContext(LoggedInUserDetails);
  //const {childName,setChildName}= React.useState("");
  const [studentId, setStudentId] = React.useState(loggedInUserInfo.studentId);
  const [orgId] = React.useState(loggedInUserInfo.orgId);
  const [examinationName, setExaminationName] = React.useState(
    props.location.state.examination
  );
  const [examName, setExamName] = React.useState("");
  const [allowPracticeExam, setAllowPracticeExam] = React.useState(false);
  const [mockTestHeading, setMockTestHeading] = React.useState("Practice Exam");
  const examStatus = props.location.state.examCompleted;
  const [mainTestHeading, setMainTestHeading] = React.useState(
    props.location.state.examination === "agc" &&
      studentInfo.examination === "agc"
      ? "Main Exam"
      : ""
  );

  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [groupId, setGroupId] = React.useState(0);

  //need to load from backend
  const [viewHistoryAllowed, setViewHistoryAllowed] = React.useState(false);

  const [openViewHistory, setOpenViewHistory] = React.useState(false);
  const [classId] = React.useState(loggedInUserInfo.classId);
  const [confirmationFlag, setConfirmationFlag] = useState(false);
  const text = smallScreen ? "View Scores" : "View Practice Test Scores";
  const [refresh, setRefresh] = React.useState(false);
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const[abortAndInprogressTitle,setAbortAndInprogressTitle] = useState("");
  const[abortAndInprogressContent,setAbortAndInprogressContent] = useState("");
  const[backDrop,setBackDrop] = useState(true);
  const handleCloseBackdrop = () => {
    setOpenBackDrop(false);
  };
  useEffect(() => {
    if (loggedInUserInfo.studentId > 0) {
      if(backDrop){
        setOpenBackDrop(true);
      }
      axios
        .get(
          BASE_URL +
            `/examinationParticipation/exam/validate/student/attempts/${loggedInUserInfo.studentId}`,
          {
            headers: {
              authorization: "Bearer " + localStorage.getItem("authorization"),
            },
          }
        )
        .then((response) => {
          setAllowPracticeExam(response.data.responseData.allowExam);
          //setLoggedInUserInfo({...loggedInUserInfo,noOfAttempts:response.data.responseData.attemptDone})
          setRefresh(false);
          setOpenBackDrop(false);
          if (response.data.responseData.allowExamAfterInprogress){
          if (response.data.responseData.allowExamAfterAborted){
          if (
            loaded !== undefined &&
            response.data.responseData.allowExam !== undefined
          ) {
            if (
              loaded === true &&
              response.data.responseData.allowExam === true &&
              classId !== null
            ) {
              setLoaded(false);
              axios
                .get(
                  BASE_URL +
                    `/examinationParticipation/batch/attach/student/${loggedInUserInfo.studentId}/${loggedInUserInfo.orgId}/${loggedInUserInfo.parentOrgId}/${loggedInUserInfo.classId}`,
                  {
                    headers: {
                      authorization:
                        "Bearer " + localStorage.getItem("authorization"),
                    },
                  }
                )
                .then((response) => {
                  //for (var i = 0; i < response.data.responseData.length; i++) {
                  if (response.data.responseData.mockExam) {
                    setMockTestFlag(true);
                    setMockTestHeading(response.data.responseData.examName);
                    setExamName(response.data.responseData.examName);
                    // setNoOfAttempts(response.data[i].noOfAttempts)
                    setMockObject({
                      batchId: response.data.responseData.batchId,
                      studentBatchScheduleId:
                        response.data.responseData.studentBatchScheduleId,
                      examParticipationId: 0,
                      exam: {
                        examId: response.data.responseData.examId,
                        name: response.data.responseData.examName,
                        duration: response.data.responseData.examDuration,
                        viewPracticeScores:
                          response.data.responseData.viewPracticeScores,
                        recordPracticeScores:
                          response.data.responseData.recordPracticeScores,
                        allowMultipleTestAttempts:
                          response.data.responseData.allowMultipleTestAttempts,
                        groupId: response.data.responseData.groupId,
                        classId: response.data.responseData.classId,
                        subjectId: response.data.responseData.subjectId,
                      },
                      studentId: loggedInUserInfo.studentId,
                      studentName: loggedInUserInfo.childName,
                      isMokeTest: response.data.responseData.mockExam,
                      examination: props.location.state.examination,
                    });
                    setViewHistoryAllowed(
                      response.data.responseData.viewPracticeScores
                    );
                    setGroupId(response.data.responseData.groupId);
                    setConfirmationFlag(true);
                    setAbortDialog(false);
                    setIsAbored(false)
                  }
                });
            }
          }
        }else{
          setAbortAndInprogressTitle("Session Aborted");
          setAbortAndInprogressContent("You had aborted or had internet issues for 3 practice sessions today. You can start your next practice tomorrow");
          setMockTestFlag(false)
          setLoaded(true)
          setAbortDialog(true)
          
        }
      }else{
        setAbortAndInprogressTitle("Session In-Progress");
        setAbortAndInprogressContent(" Your earlier practice session is in progress/ has been aborted !. Kindly try after 30 minutes. ");
        setMockTestFlag(false)
        setLoaded(true)
        setAbortDialog(true)
      }
        });
        
    }
  }, [refresh, loggedInUserInfo.studentId]);

  function refreshPage() {
    //  window.location.reload(false);
    setRefresh(true);
  }

  const [value, setValue] = React.useState(0);

  const handleTabChange = async (event, newValue) => {
    setValue(newValue);
  };
  const [maxWidth] = React.useState('xs');
  useEffect(() => {
    const interval = setInterval(() => {
    setBackDrop(false)
    setRefresh(true)
    }, 10000); //set your time here. repeat every 5 seconds
  
    return () => clearInterval(interval);
  }, []);
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openAlert}
        onClose={handleAlertClose}
        key={vertical + horizontal}
        autoHideDuration={6000}
      >
        <Alert onClose={handleAlertClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
      <Backdrop
        open={openBackDrop}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        onClick={handleCloseBackdrop}
      >
        {" "}
        <CircularProgress color="inherit" />
      </Backdrop>
      <Paper
        elevation={2}
        style={{ marginTop: "4px", backgroundColor: "#f38020" }}
      >
        <Tabs
          value={value}
          variant="fullWidth"
          TabIndicatorProps={{style: {height: 2, backgroundColor: "black" ,width:"50%"}}}
          aria-label="dashboard tabs"
          onChange={handleTabChange}
          scrollButtons="auto"
          forceRenderTabPanel={true}
        >
          {
            <Tab
              label={"DASHBOARD"}
              {...a11yProps(0)}
              style={{ fontWeight: "bold", color: "black", fontSize: "15px",fontFamily: "FilsonSoftW03-Medium",backgroundImage:`url(${value == 0 ? boxback :boxwhite})` , height: "1vh",backgroundRepeat: "no-repeat",
              backgroundSize: "150%",
              backgroundPosition: "center",width:"1%"}}
              forceRenderTabPanel={true}
            />
          }
          <Tab
            label={"PRACTICE"}
            {...a11yProps(1)}
            style={{
              fontWeight: "bold",
              color: "black",
              fontSize: "15px",
              fontFamily: "FilsonSoftW03-Medium",
              backgroundImage:`url(${value == 1 ? boxback :boxwhite})` , height: "1vh",backgroundRepeat: "no-repeat",
              backgroundSize: "150%",
              backgroundPosition: "center",width:"1%"
            }}
            forceRenderTabPanel={true}
          />
        </Tabs>
      </Paper>
      {
        <TabPanel value={value} index={0}>
          <SummaryDashBoard refresh={refresh} {...props}/>
        </TabPanel>
      }
      <TabPanel value={value} index={1}>
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.gridItem}
        >
          <Grid container direction="row">
            <Grid
              item
              container
              xs={12}
              md={12}
              style={{ marginTop: "5px" }}
              spacing={smallScreen ? 1 : 3}
            >
              <Grid item xs={0} md={7}></Grid>

              <Grid item container xs={12} md={5} justify="flex-end">
                {
                  <Button
                    color="primary"
                    variant="contained"
                    size={"small"}
                    onClick={viewHistory}
                    endIcon={<HistoryIcon />}
                    className={
                      smallScreen
                        ? classes.practiceScoreButtonMobile
                        : classes.practiceScoreButton
                    }
                  >
                    {text}
                  </Button>
                }

                <PracticeExamHistory
                  onClose={() => closeViewHistory()}
                  studentId={loggedInUserInfo.studentId}
                  groupId={groupId}
                  studentName={loggedInUserInfo.childName}
                  open={openViewHistory}
                  examName={"Practice Test"}
                ></PracticeExamHistory>

                {
                  <Button
                    color="primary"
                    variant="contained"
                    size={"small"}
                    onClick={() => {
                      setBackDrop(true)
                      setRefresh(true);
                    }}
                    endIcon={<RefreshIcon />}
                    className={
                      smallScreen
                        ? classes.refreshButtonMobile
                        : classes.refreshButton
                    }
                  >
                    Refresh
                  </Button>
                }
              </Grid>
            </Grid>

            <Grid
              container
              spacing={10}
              className={classes.paper}
              justify="center"
            >
              <Grid item xs={12} md={6}>
                <Card
                  className={classes.card}
                  onClick={onInstructionsClick}
                  elevation={6}
                >
                  <CardActionArea>
                    <CardMedia
                      className={classes.media}
                      image={smallScreen ? InstructionMobile : Instruction}
                      component="img"
                      title="Instructions"
                    />
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card className={classes.card} elevation={6}>
                  <CardActionArea
                    disabled={false}
                    style={{ opacity: !mockTestFlag ? "0.5" : "1" }}
                    onClick={() => {
                      setIsExam(false);
                      onTestClick(mockObject);
                    }}
                  >
                    <CardMedia
                      className={classes.media}
                      image={smallScreen ? MockTestImageMobile : MockTestImage}
                      component="img"
                      title="Practice Test"
                    />
                  </CardActionArea>
                </Card>
              </Grid>

              <Grid item xs={12} md={4}>
                <Card className={classes.card} elevation={6}>
                  <CardActionArea
                    disabled={!examFlag}
                    style={{ opacity: !examFlag ? "0.5" : "1" }}
                    onClick={() => {
                      axios
                        .get(
                          BASE_URL +
                            `/examinationParticipation/batch/attach/student/${loggedInUserInfo.studentId}/${loggedInUserInfo.orgId}/${loggedInUserInfo.parentOrgId}/${loggedInUserInfo.classId}`,
                          {
                            headers: {
                              authorization:
                                "Bearer " +
                                localStorage.getItem("authorization"),
                            },
                          }
                        )
                        .then((response) => {
                          for (var i = 0; i < response.data.length; i++) {
                            if (
                              response.data.length === 1 &&
                              response.data[i].exam.mockExam
                            ) {
                              setState({
                                ...state,
                                openAlert: true,
                                message: "Test is started already!",
                                severity: "error",
                              });
                              setIsExam(false);
                            } else {
                              setIsExam(true);
                              onTestClick(testObject);
                            }
                          }
                        });
                    }}
                  >
                    {confirmationFlag ? (
                      <ConfirmDialogBox
                        selectedValue={selectedValue}
                        examinationType={props.location.state.examination}
                        examObject={isExam ? testObject : mockObject}
                        open={open}
                        onClose={handleClose}
                        style={{ textAlign: "center" }}
                        md={4}
                        sm={12}
                      />
                    ) : (
                      " "
                    )}
                    {abortDialog ? (
                     <Dialog
                     fullWidth className={classes.dialogBoxHeight} maxWidth={maxWidth} 
                     open={isAborted}
                     onClose={handleAbortedClose}
                     aria-labelledby="alert-dialog-title"
                     aria-describedby="alert-dialog-description"
                   >
                     <DialogTitle style={{textAlign:'center'}}>
                      {abortAndInprogressTitle}
                     </DialogTitle>
                     <DialogContent style={{textAlign:'center'}}>
                      {abortAndInprogressContent}
                      </DialogContent>
                     <DialogActions style={{justifyContent:'center'}}>
                       <Button style={{backgroundColor:'blue',color:'white'}} onClick={handleAbortedClose}>Close</Button>
                     </DialogActions>
                   </Dialog>
                    ) : (
                      " "
                    )}
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </TabPanel>
    </div>
  );
}

export default StudentDashboard;
