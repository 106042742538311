import React from 'react';
import {
    Button,
    Grid,
    Typography,
    CircularProgress
} from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import AddIcon from '@material-ui/icons/AddRounded';
import axios from 'axios';
import EditOutLinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutLinedIcon from '@material-ui/icons/DeleteOutline';
import IconButton from '@material-ui/core/IconButton';

const BASE_URL = `${process.env.REACT_APP_API_URL}`;
class ExaminationList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pageNo: 0,
            recordsPerPage: 10,
            sortKey: "lastUpdatedDt",
            sortDir: "desc",
            count: 0,
            data: [["Loading Data..."]],
            columns: [
                {
                    name: "name",
                    label: "Name",
                    options: {}
                },
                {
                    name: "description",
                    label: "Description",
                    options: {
                        filter: false
                    }
                },
                {
                    name: "startDt",
                    label: "Start Date",
                    options: {
                        filter: false
                    }
                },
                {
                    name: "endDt",
                    label: "End Date",
                    options: {
                        filter: false
                    }
                },
                {
                    name: "organization.name",
                    label: "Organization",
                    options: {
                        filter: false,
                        display: false,
                    }
                },
                {
                    name: "branch.name",
                    label: "Branch",
                    options: {
                        filter: true,
                        display: false,
                    }
                },
                {
                    name: "lastUpdatedDt",
                    label: "Last Updated Date",
                    options: {
                        filter: false
                    }
                },
                {
                    name: "lastUpdatedBy",
                    label: "Last Updated By",
                    options: {
                        filter: false
                    }
                },
                {
                    name: "createdDt",
                    label: "Created Date",
                    options: {
                        filter: false,
                        display: false,
                    }
                },
                {
                    name: "createdBy",
                    label: "Created By",
                    options: {
                        filter: false,
                        display: false,
                    }
                },

                {
                    name: "action",
                    label: "Actions",
                    options: {
                        filter: false,
                        sort: false,
                        empty: true,
                        customBodyRenderLite: (dataIndex) => {
                            return (
                                <Grid style={{ marginLeft: -13 }} container justify="center">
                                    <IconButton color="primary" onClick={() => {
                                    }}>
                                        <EditOutLinedIcon />
                                    </IconButton>

                                    <IconButton color="secondary" onClick={() => {
                                    }}>
                                        <DeleteOutLinedIcon />
                                    </IconButton>
                                </Grid>

                            );
                        }
                    }
                }
            ],
            isLoading: false,
            body: {
                name: "",
                branch: {
                    name: ""
                }

            }

        };

        this.getData = this.getData.bind(this);
        this.handleFilterSubmit = this.handleFilterSubmit.bind(this);
        this.sort = this.sort.bind(this);
    }

    componentDidMount() {
        this.getData(this.state.pageNo, this.state.sortKey, this.state.sortDir, this.state.recordsPerPage);
    }

    handleFilterSubmit = (applyFilters) => {
        let filterList = applyFilters();
        let selectedNameFilterValue = "";
        let selectedBranchNameFilterValue = "";
        filterList[0].forEach(element => {
            selectedNameFilterValue = element;
        });
        filterList[1].forEach(element => {
            selectedBranchNameFilterValue = element;
        });

        this.setState({
            body: {
                name: selectedNameFilterValue,
                branch: {
                    name: (selectedBranchNameFilterValue !== "" ? selectedBranchNameFilterValue : "")
                }
            }
        }, () => this.getData(this.state.page, this.state.sortKey, this.state.sortDir, this.state.recordsPerPage));

        // this.getData(this.state.page, this.state.sortKey, this.state.sortDir, this.state.recordsPerPage);
    }
    
    async getData(pageNo, sortKey, sortDir, recordsPerPage) {
        this.setState({ isLoading: true });
        await axios.get(BASE_URL + "/examinations",
            {
                params: {
                    body: JSON.stringify(this.state.body),
                    pageNo: pageNo !== undefined ? pageNo : "",
                    sortKey: sortKey !== undefined ? sortKey : "",
                    sortDir: sortDir !== undefined ? sortDir : "",
                    recordsPerPage: recordsPerPage !== undefined ? recordsPerPage : "",
                },
                headers: {
                    authorization: 'Basic ' + window.btoa(`admin:admin`)
                }
            }).then((response) => {
                this.setState({ data: response.data.content, count: response.data.totalElements });
            });
        this.setState({ isLoading: false });

    }

    changePage = (page, sortOrder, rowsPerPage) => {
        this.setState({
            isLoading: true,
        });
        this.getData(page, sortOrder.name, sortOrder.direction, rowsPerPage);
    }

    changeRecordPerPage = (page, sortOrder, rowsPerPage) => {
        this.getData(page, "", "", rowsPerPage);
    }

    sort = (page, sortOrder, rowsPerPage) => {
        this.getData(page, sortOrder.name, sortOrder.direction, rowsPerPage);
    }

    handleSearch = (searchText, page, sortOrder, rowsPerPage) => {
        this.setState({
            pageNo: page,
            sortKey: sortOrder.name,
            sortDir: sortOrder.direction,
            body: {
                name: (searchText != null ? searchText : ""),
                branch: {
                    name: ""
                }
            }
        }, () => this.getData(page, sortOrder.name, sortOrder.direction, rowsPerPage));
    }

    render() {

        const { count,  rowsPerPage, sortOrder } = this.state;

        const options = {
            filter: true,
            filterType: 'dropdown',
            responsive: 'vertical',
            serverSide: true,
            count: count,
            rowsPerPage: rowsPerPage,
            rowsPerPageOptions: [1, 2, 5, 10, 20],
            sortOrder: sortOrder,
            confirmFilters: true,
            selectableRows: false,
            rowHover: true,
            caseSensitive: true,
            enableNestedDataAccess: '.',
            customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
                return (
                    <div style={{ marginTop: '40px' }}>
                        <Grid container>
                            <Grid item xs={5}>
                                <Button color="primary" variant="contained" onClick={() => this.handleFilterSubmit(applyNewFilters)}>Filter</Button>
                            </Grid>
                            {/* <Grid item xs={2}>

                          </Grid>
                          <Grid item xs={5}>
                          <Button color="primary" variant="contained" onClick={() => this.handleFilterSubmit()}>Filter</Button>
                          </Grid> */}
                        </Grid>
                    </div>
                );
            },

            // callback that gets executed when filters are confirmed
            onFilterConfirm: (filterList) => {
            },

            onFilterDialogOpen: () => {
            },
            onFilterDialogClose: () => {
            },
            searchPlaceholder: 'Search by Name...',
            onTableChange: (action, tableState) => {


                switch (action) {
                    case 'changePage':
                        this.changePage(tableState.page, tableState.sortOrder, tableState.rowsPerPage);
                        break;
                    case 'sort':
                        this.sort(tableState.page, tableState.sortOrder, tableState.rowsPerPage);
                        break;
                    case 'changeRowsPerPage':
                        this.changeRecordPerPage(tableState.page, tableState.sortOrder, tableState.rowsPerPage);
                        break;
                    case 'search':
                        this.handleSearch(tableState.searchText, tableState.page, tableState.sortOrder, tableState.rowsPerPage);
                        break;
                    default:
                        console.log('action not handled.');
                }
            }
        };

        return (
            <div className="container">
                <Grid container spacing={1}>
                    <Grid container item xs={12} justify="flex-end">
                        <Button variant="contained" color="primary" size="small"
                            endIcon={<AddIcon />}>
                            Create
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <MUIDataTable
                            title={<Typography variant="h6">
                                Examination
                        {this.state.isLoading && <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />}
                            </Typography>
                            }
                            data={this.state.data}
                            columns={this.state.columns}
                            options={options}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default ExaminationList;
