import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import logo from "../../Examination/contestLanding/images/sip_abacus_logo.png";
import { Button, Typography } from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Tooltip from "@material-ui/core/Tooltip";
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { useEffect, useContext } from "react";
import {  useTheme,useMediaQuery}from "@material-ui/core";
import axios from "axios";
import { LoggedInUserDetails } from "../LoggedInUserDetails/LoggedInUserDetails";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    // backgroundColor:"#fef9e7",
    // backgroundColor: "#fad7a0"
    backgroundColor: "#fcebb9",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  LogOutButton: {
    background: "#eb4442",
    color: "white",
    fontFamily: "FilsonSoftW03-Heavy"
  },
}));

const StudentPortal = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [studentInfo] = React.useState(
    jwt_decode(localStorage.getItem("authorization"))
  );
  const [loginId] = React.useState(studentInfo.sub);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [childName, setChildName] = React.useState("");
  const [studentId, setStudentId] = React.useState("");
  const { loggedInUserInfo, setLoggedInUserInfo } =
    React.useContext(LoggedInUserDetails);
  const BASE_URL = `${process.env.REACT_APP_API_URL}`;
  const current_Date = new Date();
  const date = `${current_Date.getFullYear()}-${(
    "0" +
    (current_Date.getMonth() + 1)
  ).slice(-2)}-${current_Date.getDate()}`;
  let theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {
    axios
      .get(
        BASE_URL + `/master/studentReg/info/referenceId/${studentInfo.sub}`,
        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("authorization"),
          },
        }
      )
      .then((response) => {
        setChildName(response.data.responseData.childName);
        setStudentId(response.data.responseData.studentId);
        setLoggedInUserInfo({
          ...loggedInUserInfo,
          childName: response.data.responseData.childName,
          studentId: response.data.responseData.studentId,
          orgId: response.data.responseData.orgId,
          classId: response.data.responseData.classId,
          parentOrgId:response.data.responseData.parentOrgId
        });
        // refreshPage();
        localStorage.setItem("childName", response.data.responseData.childName);
        localStorage.setItem("studentId", response.data.responseData.studentId);
        localStorage.setItem("orgId", response.data.responseData.orgId);
        localStorage.setItem("classId", response.data.responseData.classId);
      
        if(new Date()>new Date(response.data.responseData.validityEndDt)){
          history.push("/student/portal/invalid");
        }

      });
  }, []);
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    let redirectTo = "/practice/student/login";
    history.push(redirectTo);
    setLoggedInUserInfo({
      ...loggedInUserInfo,
      childName: "",
      studentId: 0,
      orgId: 0,
      classId: 0,
    });
  };

  const navigateBackToLoginPage = () => {
    let redirectTo = "/practice/student/login";
    history.push(redirectTo);
    setLoggedInUserInfo({
      ...loggedInUserInfo,
      childName: "",
      studentId: 0,
      orgId: 0,
      classId: 0,
    });
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>Log out</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <img src={logo} alt="" style={{ height: "50px" }} />

          <div className={classes.root} />
          {smallScreen ? (
            <>
              <Typography
                style={{ marginRight: "5px" ,fontSize:"12px",fontFamily: "FilsonSoftW03-Heavy"}}
              >WELCOME {`${childName} | ${loginId}`}</Typography>
            </>
          ) : (
            <>
              <Typography variant="h6" style={{ fontFamily: "FilsonSoftW03-Heavy"}}>
                WELCOME &nbsp;
              </Typography>
              <Typography
                variant="body1"
                style={{ marginRight: "5px",fontFamily: "FilsonSoftW03-Medium" }}
              >{`${childName} | ${loginId}`}</Typography>
            </>
          )}
          <div className={classes.sectionDesktop}>
            <Button
              className={classes.LogOutButton}
              variant="contained"
              endIcon={<ExitToAppIcon />}
              onClick={navigateBackToLoginPage}
            >
              Log out
            </Button>
          </div>
          <div className={classes.sectionMobile}>
            <Tooltip title="Logout" aria-label="logout">
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleClose}
                color="inherit"
              >
                <ExitToAppIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      <div className={classes.root}>
        <Toolbar />
        {children}
      </div>
    </div>
  );
};

export default StudentPortal;
