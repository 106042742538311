import React, { useEffect, useState, useLayoutEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  Button,
  InputLabel,
  FormControl,
  FormHelperText,
  Grid,
  Tab,
  Tabs,
  Box,
  Typography,
  MenuItem,
  Select,
  useMediaQuery,
  FormControlLabel,
  Checkbox,
  Snackbar,
  CircularProgress,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import _ from "lodash/fp";
import PropTypes from "prop-types";
import Slide from "@material-ui/core/Slide";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import axios from "axios";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import ResetOutlinedIcon from "@material-ui/icons/LoopOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import { Autocomplete, Alert } from "@material-ui/lab";
import jwt_decode from "jwt-decode";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const useStyles = makeStyles((theme) => ({
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  error: {
    color: "red",
    fontSize: "14px",
    marginLeft: "20%",
  },
  margin: {
    marginTop: theme.spacing(1),
  },
  titleAlignment: {
    marginBottom: "1px",
  },
  actionButtons: {
    marginRight: theme.spacing(3),
  },
  checkboxSize: {
    width: "18px",
    height: "18px",
  },
  dialogTitleContainer: {
    height: "30px",
  },
  gridItem: {
    paddingBottom: theme.spacing(0.5),
    color: "darkslategrey",
  },
  gridItemSelect: {
    paddingBottom: theme.spacing(1),
    width: "291px",
    paddingTop: theme.spacing(0.5),
  },
  gridItemSelectGender: {
    paddingBottom: theme.spacing(1),
    width: "60%",
    paddingTop: theme.spacing(0.5),
  },
  formControlSelect: {
    "& .MuiFormLabel-root": {
      color: "darkslategrey",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "darkslategrey",
      },
    },
    minWidth: "60%",
    textAlign: "left",
    "& .MuiFormHelperText-contained": {
      margin: 0,
      color: "darkslategrey",
    },
  },
  invalidSelect: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red",
        borderWidth: 2,
      },
    },
    minWidth: "60%",
    textAlign: "left",
    "& .MuiFormHelperText-contained": {
      margin: 0,
      display: "none",
    },
  },
  formControl: {
    "& .MuiFormLabel-root": {
      color: "darkslategrey",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "darkslategrey",
      },
    },
    minWidth: "60%",
    textAlign: "center",
    "& .MuiFormHelperText-contained": {
      margin: 0,
      color: "darkslategrey",
    },
  },
  invalid: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red",
        borderWidth: 2,
      },
    },
    minWidth: "60%",
    textAlign: "center",
    "& .MuiFormHelperText-contained": {
      margin: 0,
      display: "none",
    },
  },
  dialogPaper: {
    minHeight: "65%",
    maxHeight: "68%",
    minWidth: "40%",
    maxWidth: "40%",
  },
  workshopDate: {
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "darkslategrey",
    },
    "& .MuiFormLabel-root": {
      color: "darkslategrey",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "darkslategrey",
      },
    },
    minWidth: "60%",
    textAlign: "center",
    "& .MuiFormHelperText-contained": {
      margin: 0,
      color: "darkslategrey",
    },
  },
}));

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function EditStudent(props) {
  const classes = useStyles();
  const theme = useTheme();
  const {
    onClose,
    studentObject,
    open,
    currentDateAndTimeFromServer,
    studentEditPermissionFlag,
  } = props;
  const [title, setTitle] = useState("Edit");
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [buttonLabel, setButtonLabel] = useState("Update");
  //const [resetButtonStatus, setRestButtonStatus] = useState(false);
  const [classNameList, setClassNameList] = useState([]);

  const { control, register, handleSubmit, errors, watch, reset, getValues } =
    useForm({
      mode: "all",
    });
  const [editedStudentData, setEditStudentData] = React.useState(studentObject);
  const [updateButtonStatus, setUpdateButtonStatus] = React.useState(false);
  const [studentData, setStudentData] = React.useState({});
  const BASE_URL = `${process.env.REACT_APP_API_URL}`;
  const username = "admin";
  const password = "admin";
  const [emptySipAbacusStudent, setEmptySipAbacusStudent] =
    React.useState(false);
  const [isWorkshopScheduled, setIsWorkshopScheduled] = React.useState(false);
  const currentDate = new Date(currentDateAndTimeFromServer);
  const [workshopDate, setWorkshopDate] = useState("");
 //const [attendedDate, setAttendedDate] = 
 // useState(studentData.workShopAttendedDate===null || studentData.workShopAttendedDate===undefined || studentData.workShopAttendedDate===''?new //Date:new Date(studentData.workShopAttendedDate));
 const [attendedDate, setAttendedDate] = useState(null);
  const [userInfo] = React.useState(
    jwt_decode(localStorage.getItem("sip-authorization"))
  );
  const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] =
    React.useState(false);
  const [state, setState] = React.useState({
    openAlert: false,
    vertical: "top",
    horizontal: "center",
    message: "",
    severity: "",
  });

  const { vertical, horizontal, openAlert, message, severity } = state;

  const handleAlertClose = () => {
    setState({ ...state, openAlert: false });
  };

  const onSubmit = (data) => {
    setIsUpdateButtonDisabled(true);
    setButtonLabel("Updating...");
    if (
      !(
        studentData.workshop === undefined ||
        studentData.workshop === null ||
        studentData.workshop === ""
      )
    ) {
      studentData.workshop = {
        workshopId: studentData.workshop.workshopId
        
      };
    } else {
      studentData.workshop = null;
    }
    studentData.workshopAttendedDate= attendedDate;
    
    
    axios
      .post(BASE_URL + "/students/" + studentData.studentId, studentData, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("sip-authorization"),
        },
      })
      .then((response) => {
        setWorkshopDate("");
        setIsUpdateButtonDisabled(false);
        setButtonLabel("Update");
        onClose(true);
      })
      .catch(function (error) {
        setState({
          ...state,
          openAlert: true,
          message: "Failed to update the record!",
          severity: "error",
        });
        setButtonLabel("Update");
        setIsUpdateButtonDisabled(false);
      });
  };

  useEffect(() => {
    
    setAttendedDate((studentObject.student.workshopAttendedDate===null || studentObject.student.workshopAttendedDate===undefined || studentObject.student.workshopAttendedDate==='')?new Date():new Date(studentObject.student.workshopAttendedDate));
    
   
    setStudentData({
      studentId: studentObject.student.studentId,
      parentName: studentObject.student.parentName,
      mobileNumber1: studentObject.student.mobileNumber1,
      mobileNumber2: studentObject.student.mobileNumber2,
      whatsAppNumber: studentObject.student.whatsAppNumber,
      emailId: studentObject.student.emailId,
      childName: studentObject.student.childName,
      gender: studentObject.student.gender,
      schoolNameString: studentObject.student.schoolNameString,
      schoolArea: studentObject.student.schoolArea,
      classNumber: {
        classId:
          studentObject.student.classNumber === undefined
            ? ""
            : studentObject.student.classNumber.classId,
        name:
          studentObject.student.classNumber === undefined
            ? ""
            : studentObject.student.classNumber.name,
      },
      section: studentObject.student.section,
      state: {
        stateId:
          studentObject.student.stateId === undefined
            ? ""
            : studentObject.student.stateId,
        stateName:
          studentObject.student.stateName === undefined
            ? ""
            : studentObject.student.stateName,
      },
      city: {
        cityName:
          studentObject.student.cityId === undefined
            ? ""
            : studentObject.student.cityName,
        cityId:
          studentObject.student.cityName === undefined
            ? ""
            : studentObject.student.cityId,
      },
      source: studentObject.student.source,
      branch: {
        branchId:
          studentObject.student.branchId === undefined
            ? ""
            : studentObject.student.branchId,
        name:
          studentObject.student.branchName === undefined
            ? ""
            : studentObject.student.branchName,
      },
      sipRegistration: studentObject.student.sipRegistration,
      registrationCompleted: true,
      //regInCompleteNotified: studentObject.student.regInCompleteNotified,
      lastUpdatedBy: userInfo.sub,
      createdBy: studentObject.student.createdBy,
      createdDt: studentObject.student.createdDt,
      sipAbacusStudent:
        studentObject.student.sipAbacusStudent !== null
          ? studentObject.student.sipAbacusStudent
          : "",
      workshop:
        studentObject.student.workshop === null
          ? ""
          : studentObject.student.workshop,
          workshopAttendedDate:
          studentObject.student.workshopAttendedDate === null || studentObject.student.workshopAttendedDate === undefined || studentObject.student.workshopAttendedDate === ""
            ? null
            : studentObject.student.workShopAttendedDate,
      registrationId: studentObject.student.registrationId,
      active: studentObject.student.active,
      examination: {
        examinationId:
          studentObject.student.examinationId !== undefined
            ? studentObject.student.examinationId
            : 0,
      },
      schoolNameVerified: studentObject.student.schoolNameVerified,
      schoolName: {
         schoolId: studentObject.student.schoolId,
         schoolName: studentObject.student.schoolName,
         schoolArea: studentObject.student.schoolArea, 
         schoolNameDisp:studentObject.student.schoolNameDisp        
      },
      examId : studentObject.student.examId,
      examGroupId : studentObject.student.examGroupId,

    });
    setWorkshopDate("");


    setTitle("Edit");
    // reset({
    //     classId: `${classObject.class.classId !== undefined ? classObject.class.classId : 0}`,
    //     name: `${classObject.class.name !== undefined ? classObject.class.name : ""}`,
    //     organization: {
    //         value: classObject.class.organization.organizationId,
    //         label: classObject.class.organization.name,
    //         organizationId: classObject.class.organization.organizationId },
    //     branch: {
    //         value: classObject.class.branch.branchId,
    //         label: classObject.class.branch.name,
    //         branchId: classObject.class.branch.branchId },
    //     active: classObject.class.active ? true : false
    // });
    // setNameFieldLength(classObject.class.name.length);
    setButtonLabel("Update");
    //  setRestButtonStatus(true);
  }, [studentObject]);

  useEffect(() => {
    if (
      errors.parentName ||
     // errors.mobileNumber1 ||
      errors.whatsAppNumber ||
     // errors.emailId ||
      errors.childName ||
      errors.schoolName ||
  //    errors.schoolArea ||
      errors.classNumber ||
    //  errors.section ||
    //  errors.state ||
    //  errors.city ||
      errors.branch ||
      //!studentData.city ||
      !studentData.branch ||
      errors.sipAbacusStudent
    ) {
      setUpdateButtonStatus(true);
    } else {
      setUpdateButtonStatus(false);
    }
    if (
      studentData.sipAbacusStudent === "" ||
      studentData.sipAbacusStudent === null
    ) {
      setEmptySipAbacusStudent(true);
    } else {
      setEmptySipAbacusStudent(false);
    }
  }, [
    errors.parentName,
   // errors.mobileNumber1,
    errors.whatsAppNumber,
  //  errors.emailId,
    errors.childName,
    errors.schoolName,
   // errors.schoolArea,
    errors.classNumber,
    errors.sipAbacusStudent,
   // errors.section,
   // errors.state,
   // errors.city,
    errors.branch,
    studentData.branch,
   // studentData.city,
  ]);

  useEffect(() => {
    if (studentData.workshop && studentData.workshop.workshopId > 0) {
      if (studentData.workshopAttendedDate !== null) {
        setWorkshopDate(studentData.workshopAttendedDate);
      } else {
        setWorkshopDate("");
      }
    } else {
      setWorkshopDate("");
    }
  }, [studentData.workshop]);

  const handleClose = () => {
    // reset({
    //      name: "",
    //      organization: "",
    //      branch: ""
    // });
    //setNameFieldLength(0);
    //  setRestButtonStatus(false);
    onClose(false);
  };

  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [stateID, setStateID] = useState("");
  const [cityID, setCityID] = useState("");
  const [stateValue, setStateValue] = React.useState("");
  const [cityValue, setCityValue] = React.useState("");
  const [branchValue, setBranchValue] = React.useState("");
  const [classValue, setClassValue] = React.useState("");
  const [schoolNameList, setSchoolNameList] = React.useState([]);
  const [schoolName, setSchoolName]= React.useState('');

  const loadSchoolNameData = () => {
    setSchoolNameList([{ schoolName: "Loading..." }]);
    axios
      .get(BASE_URL + "/schoolName", {
        params: {
          body: JSON.stringify({ schoolName: schoolName != null ? schoolName:'',approved :true,
          active:true,
          state:{
              stateId:0
          }}),
        },
        headers: {
          authorization: "Basic " + window.btoa(username + ":" + password),
        },
      })
      .then((response) => {
        setSchoolNameList(response.data.content);
      });
  };

  const schoolNameData = schoolNameList.map((schoolName) => {
    return {
      schoolName: schoolName.schoolName,
      schoolId: schoolName.schoolId,
      schoolArea: schoolName.schoolArea,
      schoolNameDisp:schoolName.schoolNameDisp
    };
  });

  const loadStateData = () => {
    setStateList([{ stateName: "Loading..." }]);
    axios
      .get(BASE_URL + "/states", {
        params: {
          body: JSON.stringify({ stateName: "" ,active:false}),
        },
        headers: {
          authorization: "Basic " + window.btoa(username + ":" + password),
        },
      })
      .then((response) => {
        setStateList(response.data.content);
      });
  };

  const loadBranchData = () => {
    setBranchList([{ name: "Loading..." }]);
    axios
      .get(BASE_URL + "/branches", {
        params: {
          body: JSON.stringify({
            name: "",
            address: {
              countryId: 1,
              stateId: stateID,
              cityId: cityID,
            },
          }),
        },
      })
      .then((response) => {
        setBranchList(response.data.content);
      });
  };

  const loadCityData = () => {
    setCityList([{ cityName: "Loading..." }]);
    axios
      .get(BASE_URL + "/cities", {
        params: {
          body: JSON.stringify({
            cityName: "",
            countryCombo: {
              countryId: 1,
            },
            stateCombo: {
              stateId: stateID,
            },
          }),
        },
        headers: {
          authorization: "Basic " + window.btoa(username + ":" + password),
        },
      })
      .then((response) => {
        setCityList(response.data.content);
      });
  };
  const cityData = cityList.map((city) => {
    return { cityName: city.cityName, cityId: city.cityId };
  });

  const stateData = stateList.map((state) => {
    return { stateName: state.stateName, stateId: state.stateId };
  });

  const branchData = branchList.map((branch) => {
    return { name: branch.name, branchId: branch.branchId };
  });

  const tabHeadings = {
    parentDetails: "Parents Details",
    childDetails: "Child Details",
    LCLDetails: "LCL Details",
    SIPDetails: "SIP Information",
  };

  //Fields Headings or Place Holders
  const fieldsLabels = {
    parentName: (
      <span>
        Name of Parent <span style={{ color: "red" }}>*</span>
      </span>
    ),
    mobileNumber1: (
      <span>
        Mobile No. 
      </span>
    ),
    mobileNumber2: <span>Alternate Mobile No.</span>,
    whatsAppNumber: (
      <span>
        WhatsApp No. <span style={{ color: "red" }}>*</span>
      </span>
    ),
    emailId: (
      <span>
        Email 
      </span>
    ),
    childName: (
      <span>
        Name of Child <span style={{ color: "red" }}>*</span>
      </span>
    ),
    gender: (
      <span>
        Gender <span style={{ color: "red" }}>*</span>
      </span>
    ),
    schoolName: (
      <span>
        School Name <span style={{ color: "red" }}>*</span>
      </span>
    ),
    classNumber: (
      <span>
        Class <span style={{ color: "red" }}>*</span>
      </span>
    ),
    section: (
      <span>
        Section 
      </span>
    ),
    schoolArea: (
      <span>
        School Area 
      </span>
    ),
    state: (
      <span>
        State <span style={{ color: "red" }}></span>
      </span>
    ),
    city: (
      <span>
        City <span style={{ color: "red" }}></span>
      </span>
    ),
    branch: (
      <span>
        Branch <span style={{ color: "red" }}>*</span>
      </span>
    ),
    sipAbacusStudent: (
      <span>
        SIP Abacus Student<span style={{ color: "red" }}>*</span>
      </span>
    ),
    workShopAttendedDate: <span>Workshop Attended Date</span>,
    certificateSent: <span>Certificate Sent</span>,
    certificateSentDate: <span>Certificate Sent Date</span>,
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const loadSchoolData =value=>{
    setSchoolName(value)
    loadSchoolNameData()
  }

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const loadClassData = () => {
    setClassNameList([{ name: "Loading..." }]);
    axios
      .get(BASE_URL + "/classes", {
        params: {
          body: JSON.stringify({ name: "" ,examination:{examinationId:1}}),
        },
        headers: {
          authorization: "Basic " + window.btoa(username + ":" + password),
        },
      })
      .then((response) => {
        setClassNameList(response.data.content);
      });
  };

  const classData = classNameList.map((className) => {
    return { name: className.name, classId: className.classId };
  });

  const setWorkshopAttendedDate = (date) => {
    let customWorkshopId = 0;
    if (
      !(
        studentData.workshop === "" ||
        studentData.workshop === undefined ||
        studentData.workshop === null
      )
    ) {
      customWorkshopId = studentData.workshop.workshopId;
    }
    studentData.workshop = {
      workshopId: customWorkshopId,
    };
    setAttendedDate(new Date(date));
    studentData.workshopAttendedDate= date;
    
  };

  return (
    <Dialog
      fullWidth={true}
      onClose={handleClose}
      aria-labelledby="create-or-delete-dialog"
      open={open}
      disableBackdropClick={true}
      classes={{ paper: classes.dialogPaper }}
    >
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openAlert}
        onClose={handleAlertClose}
        key={vertical + horizontal}
        autoHideDuration={6000}
      >
        <Alert onClose={handleAlertClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>

      <DialogTitle className={classes.dialogTitleContainer}>
        {title} Student
      </DialogTitle>

      <DialogContent
        dividers
        className={classes.formControl}
        style={{ marginTop: "2%" }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            selectedIndex={3}
            scrollButtons="auto"
            forceRenderTabPanel={true}
            aria-label="full width tabs example"
          >
            <Tab
              label={tabHeadings.SIPDetails}
              selectedIndex={true}
              forceRenderTabPanel={true}
              {...a11yProps(0)}
            />
            <Tab label={tabHeadings.parentDetails} {...a11yProps(1)} />
            <Tab label={tabHeadings.childDetails} {...a11yProps(2)} />
            <Tab label={tabHeadings.LCLDetails} {...a11yProps(3)} />
          </Tabs>

          {/*---------------------------------------------------Parent info--------------------------------------------------- */}

          <TabPanel value={value} index={1} dir={theme.direction}>
            <Grid item xs={12} className={classes.gridItem}>
              <TextField
                className={
                  (errors.parentName && classes.invalid) || classes.formControl
                }
                size="small"
                margin="dense"
                autoComplete="off"
                variant="outlined"
                id="standard-helperText"
                label={fieldsLabels.parentName}
                name="parentName"
                onChange={() => {
                  setStudentData({
                    ...studentData,
                    ["parentName"]: getValues("parentName"),
                  });
                }}
                defaultValue={studentData.parentName}
                helperText={"Parent Name"}
                inputRef={register({
                  required: true,
                  maxLength: 100,
                })}
              />
              {_.get("parentName.type", errors) === "required" && (
                <FormHelperText className={classes.error}>
                  Name is required
                </FormHelperText>
              )}
              {_.get("parentName.type", errors) === "maxLength" && (
                <FormHelperText className={classes.error}>
                  Name cannot exceed 100 characters
                </FormHelperText>
              )}
            </Grid>

            <Grid item xs={12} className={classes.gridItem}>
              <TextField
                className={
                 
                  classes.formControl
                }
                size="small"
                margin="dense"
                variant="outlined"
                id="mobileNumber1"
                autoComplete="off"
                label={fieldsLabels.mobileNumber1}
                name="mobileNumber1"
                onChange={() => {
                  setStudentData({
                    ...studentData,
                    ["mobileNumber1"]: getValues("mobileNumber1"),
                  });
                }}
                defaultValue={studentData.mobileNumber1}
                helperText={`Mobile number`}
                inputRef={register({
                  required: false,
                  maxLength: 10,
                  minLength: 10,
                  pattern: /^[0-9\b]+$/,
                })}
              />

         
              {_.get("mobileNumber1.type", errors) === "maxLength" && (
                <FormHelperText className={classes.error}>
                  Mobile No cannot exceed 10 numbers
                </FormHelperText>
              )}
              {_.get("mobileNumber1.type", errors) === "minLength" && (
                <FormHelperText className={classes.error}>
                  Mobile No cannot be less than 10 numbers
                </FormHelperText>
              )}
              {_.get("mobileNumber1.type", errors) === "pattern" && (
                <FormHelperText className={classes.error}>
                  Please enter valid mobile number
                </FormHelperText>
              )}
            </Grid>

            <Grid item xs={12} className={classes.gridItem}>
              <TextField
                className={classes.formControl}
                size="small"
                margin="dense"
                variant="outlined"
                id="mobileNumber2"
                autoComplete="off"
                label={fieldsLabels.mobileNumber2}
                name="mobileNumber2"
                onChange={() => {
                  setStudentData({
                    ...studentData,
                    ["mobileNumber2"]: getValues("mobileNumber2"),
                  });
                }}
                defaultValue={studentData.mobileNumber2}
                inputRef={register({
                  required: false,
                  minLength: 10,
                  maxLength: 10,
                })}
                helperText={`Alternate mobile number`}
              />
              {_.get("mobileNumber2.type", errors) === "maxLength" && (
                <FormHelperText className={classes.error}>
                  Mobile Number cannot exceed 10 numbers
                </FormHelperText>
              )}
              {_.get("mobileNumber2.type", errors) === "minLength" && (
                <FormHelperText className={classes.error}>
                  Mobile Number cannot be less than 10 numbers
                </FormHelperText>
              )}
            </Grid>

            <Grid item xs={12} className={classes.gridItem}>
              <TextField
                className={
                  (errors.whatsAppNumber && classes.invalid) ||
                  classes.formControl
                }
                size="small"
                margin="dense"
                variant="outlined"
                id="whatsAppNumber"
                autoComplete="off"
                label={fieldsLabels.whatsAppNumber}
                name="whatsAppNumber"
                defaultValue={studentData.whatsAppNumber}
                onChange={() => {
                  setStudentData({
                    ...studentData,
                    ["whatsAppNumber"]: getValues("whatsAppNumber"),
                  });
                }}
                helperText={`WhatsApp number`}
                inputRef={register({
                  required: true,
                  minLength: 10,
                  maxLength: 10,
                  pattern: /^[0-9\b]+$/,
                })}
              />
              {_.get("whatsAppNumber.type", errors) === "required" && (
                <FormHelperText className={classes.error}>
                  WhatsApp number is required
                </FormHelperText>
              )}
              {_.get("whatsAppNumber.type", errors) === "maxLength" && (
                <FormHelperText className={classes.error}>
                  WhatsApp Number cannot exceed 10 numbers
                </FormHelperText>
              )}
              {_.get("whatsAppNumber.type", errors) === "minLength" && (
                <FormHelperText className={classes.error}>
                  WhatsApp Number cannot be less than 10 numbers
                </FormHelperText>
              )}
              {_.get("whatsAppNumber.type", errors) === "pattern" && (
                <FormHelperText className={classes.error}>
                  Please enter valid WhatsApp number
                </FormHelperText>
              )}
            </Grid>

            <Grid item xs={12} className={classes.gridItem}>
              <TextField
                className={
                  classes.formControl
                }
                size="small"
                margin="dense"
                variant="outlined"
                autoComplete="off"
                id="emailId"
                label={fieldsLabels.emailId}
                name="emailId"
                defaultValue={studentData.emailId}
                onChange={() => {
                  if (
                    /^[A-Z0-9._%+-\s]+@[A-Z0-9.-\s]+\.[A-Z\s]{2,}$/i.test(
                      getValues("emailId")
                    )
                  ) {
                    setStudentData({
                      ...studentData,
                      ["emailId"]: getValues("emailId"),
                    });
                  }
                }}
                helperText={`Email Id`}
                inputRef={register({
                  required: false,
                  maxLength: 100,
                  pattern: /^[A-Z0-9._%+-\s]+@[A-Z0-9.-\s]+\.[A-Z\s]{2,}$/i,
                })}
              />
             
              {_.get("emailId.type", errors) === "maxLength" && (
                <FormHelperText className={classes.error}>
                  Email Id cannot exceed 100 characters
                </FormHelperText>
              )}
              {_.get("emailId.type", errors) === "pattern" && (
                <FormHelperText className={classes.error}>
                  Please enter valid email id
                </FormHelperText>
              )}
            </Grid>
          </TabPanel>
          {/* ------------------------------------------------Child info-------------------------------------------------------- */}
          <TabPanel value={value} index={2} dir={theme.direction}>
            <Grid item xs={12} className={classes.gridItem}>
              <TextField
                className={
                  (errors.childName && classes.invalid) || classes.formControl
                }
                size="small"
                margin="dense"
                variant="outlined"
                id="childName"
                label={fieldsLabels.childName}
                name="childName"
                helperText={`Child name`}
                defaultValue={studentData.childName}
                onChange={() => {
                  setStudentData({
                    ...studentData,
                    ["childName"]: getValues("childName"),
                  });
                }}
                inputRef={register({
                  required: true,
                  maxLength: 100,
                })}
              />
              {_.get("childName.type", errors) === "required" && (
                <FormHelperText className={classes.error}>
                  Child name is required
                </FormHelperText>
              )}
              {_.get("childName.type", errors) === "maxLength" && (
                <FormHelperText className={classes.error}>
                  Child name cannot exceed 100 characters
                </FormHelperText>
              )}
            </Grid>

            {/* <Grid
              item
              xs={12}
              className={classes.gridItemSelectGender}
              style={{ marginLeft: smallScreen ? "0%" : "20%" }}
            >
              <FormControl
                fullWidth
                size="small"
                variant="outlined"
                className={
                  (errors.gender && classes.invalidSelect) ||
                  classes.formControlSelect
                }
              >
                <InputLabel>{fieldsLabels.gender}</InputLabel>
                <Controller
                  render={(props) => (
                    <Select
                      name="gender"
                      value={studentData.gender}
                      label={fieldsLabels.gender}
                      onBlur={(e) => {
                        props.onBlur(e);
                      }}
                      onChange={(e) => {
                        props.onChange(e);
                        setStudentData({
                          ...studentData,
                          ["gender"]: getValues("gender"),
                        });
                      }}
                    >
                      <MenuItem value={"Boy"}>Boy</MenuItem>
                      <MenuItem value={"Girl"}>Girl</MenuItem>
                    </Select>
                  )}
                  name="gender"
                  control={control}
                  defaultValue={studentData.gender}
                  rules={{ required: true }}
                ></Controller>
              </FormControl>
              {_.get("gender.type", errors) === "required" && (
                <FormHelperText className={classes.error}>
                  Please select the gender
                </FormHelperText>
              )}
              {_.get("gender.type", errors) !== "required" && (
                <FormHelperText style={{ color: "darkslategrey" }}>
                  Gender
                </FormHelperText>
              )}
            </Grid> */}

            <Grid item xs={12} className={classes.gridItemSchool}>
              <Controller
                render={(props) => (
                  <Autocomplete
                    style={{
                      marginLeft: smallScreen ? "0%" : "20%",
                      width: "60%",
                    }}
                    value={
                      studentData.schoolName === undefined
                        ? ""
                        : studentData.schoolName.schoolName
                    }
                    onChange={(event, newValue) => {
                      
                      props.onChange(newValue);
                      if (typeof newValue === "string") {
                        setClassValue({
                          newValue,
                        });
                        if (newValue != null) {
                          setSchoolName(newValue.schoolName)
                          setStudentData({
                            ...studentData,
                            ["schoolName"]: {
                              schoolId: newValue.schoolId,
                              schoolName: newValue.schoolName,
                              schoolArea: newValue.schoolArea,
                            },
                            schoolArea : newValue.inputValue.schoolArea
                          });
                        }
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setClassValue({
                          title: newValue.inputValue,
                        });


                        if (newValue != null) {
                          setSchoolName(newValue.schoolName)
                          setStudentData({
                            ...studentData,
                            ["schoolName"]: {
                              schoolId: newValue.inputValue.schoolId,
                              schoolName: newValue.inputValue.schoolName,
                              schoolArea: newValue.inputValue.schoolArea,
                            },
                            schoolArea : newValue.inputValue.schoolArea
                          });
                        }
                      } else if (newValue != null) {
                        setSchoolName(newValue.schoolName)
                        setClassValue(newValue);
                        if (newValue) {
                          setStudentData({
                            ...studentData,
                            ["schoolName"]: {
                              schoolId: newValue.schoolId,
                              schoolArea: newValue.schoolArea,
                              schoolName: newValue.schoolName,
                            },
                            schoolArea : newValue.schoolArea
                          });
                        }
                      } else {
                        setClassValue(newValue);
                        setStudentData({ ...studentData, ["schoolName"]: "" });
                        setSchoolName('')

                      }
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    onOpen={loadSchoolNameData}
                    // onInputChange={(item)=>loadSchoolData(item.target.value)} 
                   
                    options={schoolNameData}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      return option.schoolName;
                    }}
                    getOptionSelected={(option) =>
                      option.value === schoolNameList.schoolName
                    }
                    renderOption={(option) => option.schoolNameDisp}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        schoolName={
                          (errors.schoolName && classes.invalid) ||
                          classes.formControl
                        }
                        onChange={(item)=> loadSchoolData(item.target.value)}
                        name="schoolName"
                        size="small"
                        label={fieldsLabels.schoolName}
                        variant="outlined"
                        helperText="School Name"
                      />
                    )}
                  />
                )}
                rules={{ required: true }}
                name="schoolName"
                control={control}
                
                defaultValue={{
                  schoolName:studentData.schoolName === undefined? "" : studentData.schoolName.schoolName,
                  schoolArea:studentData.schoolName === undefined? "": studentData.schoolName.schoolArea,
                  schoolId:studentData.schoolName === undefined  ? "": studentData.schoolName.schoolId,
                }}
              />

              {_.get("schoolName.type", errors) === "required" && (
                <FormHelperText className={classes.error}>
                  Please select the School name
                </FormHelperText>
              )}
            </Grid>

            {/* school area */}
            <Grid item xs={12} className={classes.gridItemSchool}>
              <TextField
                className={
                  (errors.schoolArea && classes.invalid) || classes.formControl
                }
                size="small"
                margin="dense"
                variant="outlined"
                id="schoolArea"
                label={fieldsLabels.schoolArea}
                name="schoolArea"
                helperText={`School area`}
                defaultValue={studentData.schoolArea}
                disabled
                value ={studentData.schoolArea}
                onChange={() => {
                  setStudentData({
                    ...studentData,
                    ["schoolArea"]: getValues("schoolArea"),
                  });
                }}
                inputRef={register({
                  required: true,
                  maxLength: 100,
                })}
              />
           
              {_.get("schoolArea.type", errors) === "maxLength" && (
                <FormHelperText className={classes.error}>
                  School area cannot exceed 100 characters
                </FormHelperText>
              )}
            </Grid>
            <Grid
              container
              justify="center"
              alignItems="stretch"
              style={{ marginTop: 10 }}
            >
              {/* class */}
              <Grid item xs={12} className={classes.gridItemSelect}>
                <Controller
                  render={(props) => (
                    <Autocomplete
                      style={{
                        marginLeft: smallScreen ? "0%" : "20%",
                        width: "60%",
                      }}
                      value={
                        studentData.classNumber === undefined
                          ? ""
                          : studentData.classNumber.name
                      }
                      onChange={(event, newValue) => {
                        props.onChange(newValue);
                        if (typeof newValue === "string") {
                          setClassValue({
                            newValue,
                          });
                          if (newValue != null) {
                            setStudentData({
                              ...studentData,
                              ["classNumber"]: newValue,
                            });
                          }
                        } else if (newValue && newValue.inputValue) {
                          // Create a new value from the user input
                          setClassValue({
                            title: newValue.inputValue,
                          });

                          if (newValue != null) {
                            setStudentData({
                              ...studentData,
                              ["classNumber"]: newValue.inputValue,
                            });
                          }
                        } else if (newValue != null) {
                          setClassValue(newValue);
                          if (newValue) {
                            setStudentData({
                              ...studentData,
                              ["classNumber"]: newValue,
                            });
                          }
                        } else {
                          setClassValue(newValue);
                          setStudentData({
                            ...studentData,
                            ["classNumber"]: "",
                          });
                        }
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      onOpen={loadClassData}
                      options={classData}
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }

                        return option.name;
                      }}
                      getOptionSelected={(option) =>
                        option.value === classNameList.className
                      }
                      renderOption={(option) => option.name}
                      
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className={
                            (errors.classNumber && classes.invalid) ||
                            classes.formControl
                          }
                          name="classNumber"
                          size="small"
                          
                          label={fieldsLabels.classNumber}
                          variant="outlined"
                          helperText="Class"
                        />
                      )}
                    />
                  )}
                  rules={{ required: true }}
                  name="classNumber"
                  control={control}
                  defaultValue={{
                    name:
                      studentData.classNumber === undefined
                        ? ""
                        : studentData.classNumber.name,
                    classId:
                      studentData.classNumber === undefined
                        ? 1
                        : studentData.classNumber.classId,
                  }}
                />

                {_.get("classNumber.type", errors) === "required" && (
                  <FormHelperText className={classes.error}>
                    Please select the class
                  </FormHelperText>
                )}
              </Grid>
            </Grid>

            {/* section */}
            <Grid item xs={12} className={classes.gridItem}>
              <TextField
                size="small"
                className={
                  (errors.section && classes.invalid) || classes.formControl
                }
                margin="dense"
                variant="outlined"
                id="section"
                label={fieldsLabels.section}
                name="section"
                defaultValue={studentData.section}
                
                onChange={() => {
                  setStudentData({
                    ...studentData,
                    ["section"]: getValues("section"),
                  });
                }}
                helperText={`Section`}
                inputRef={register({
                  required: false,
                  maxLength: 10,
                })}
              />
              {_.get("section.type", errors) === "maxLength" && (
                <FormHelperText className={classes.error}>
                  Section cannot exceed 10 characters
                </FormHelperText>
              )}
            </Grid>
          </TabPanel>

          {/*-------------------------------------------------- CommunicationDetails ----------------------------------------------*/}

          <TabPanel value={value} index={3} dir={theme.direction}>
            <Grid item xs={12} className={classes.gridItem}>
              <Controller
                render={(props) => (
                  <Autocomplete
                  disableClearable
                    style={{
                      marginLeft: smallScreen ? "0%" : "20%",
                      width: "60%",
                    }}
                    value={
                      studentData.state === undefined
                        ? null
                        : studentData.state.stateName
                    }
                    onChange={(event, newValue) => {
                      props.onChange(newValue);
                      studentData.city = { cityName: "", cityId: 1 };
                      studentData.branch = { name: "", branchId: 1 };

                      if (typeof newValue === "string") {
                        setStateValue({
                          newValue,
                        });

                        if (newValue != null) {
                          setStateID(newValue.stateId);
                          setStudentData({
                            ...studentData,
                            ["state"]: newValue,
                          });
                        }
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setStateValue({
                          title: newValue.inputValue,
                        });
                        if (newValue != null) {
                          setStateID(newValue.stateId);
                          setStudentData({
                            ...studentData,
                            ["state"]: newValue.inputValue,
                          });
                        }
                      } else if (newValue != null) {
                        setStateValue(newValue);
                        if (newValue) {
                          setStateID(newValue.stateId);
                          setStudentData({
                            ...studentData,
                            ["state"]: newValue,
                          });
                        }
                      } else {
                        setStateValue(newValue);
                        setStateID(1);
                        setStudentData({ ...studentData, ["state"]: "" });
                      }
                      setCityList([]);
                      setBranchList([]);
                    }}
                    disabled={!studentEditPermissionFlag}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    onOpen={loadStateData}
                    options={stateData}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }

                      return option.stateName;
                    }}
                    getOptionSelected={(option) =>
                      option.value === stateList.stateName
                    }
                    renderOption={(option) => option.stateName}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        
                        className={
                          (errors.state && classes.invalid) ||
                          classes.formControl
                        }
                        name="state"
                        size="small"
                        label={fieldsLabels.state}
                        variant="outlined"
                        helperText="State"
                        disabled={!studentEditPermissionFlag}
                      />
                    )}
                  />
                )}
                rules={{ required: true }}
                name="state"
                control={control}
                defaultValue={{
                  stateName:
                    studentData.state === undefined
                      ? null
                      : studentData.state.stateName,
                  stateId:
                    studentData.state === undefined
                      ? 1
                      : studentData.state.stateId,
                }}
              />

              {_.get("state.type", errors) === "required" && (
                <FormHelperText className={classes.error}>
                  Please select the state
                </FormHelperText>
              )}
            </Grid>
            <br />

            <Grid item xs={12} className={classes.gridItem}>
              <Controller
                render={(props) => (
                  <Autocomplete
                  disableClearable
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                    style={{
                      marginLeft: smallScreen ? "0%" : "20%",
                      width: "60%",
                    }}
                    onChange={(event, newValue) => {
                      props.onChange(newValue);
                      studentData.branch = { name: "", BranchId: 1 };
                      if (typeof newValue === "string") {
                        if (newValue != null) {
                          setCityValue()({
                            newValue,
                          });
                          setCityID(newValue.cityId);
                          setStudentData({
                            ...studentData,
                            ["city"]: newValue,
                          });
                        }
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setCityValue({
                          title: newValue.inputValue,
                        });

                        if (newValue != null) {
                          setCityID(newValue.cityId);
                          setStudentData({
                            ...studentData,
                            ["city"]: newValue.inputValue,
                          });
                        }
                      } else if (newValue != null) {
                        setCityValue(newValue);

                        if (newValue) {
                          setCityID(newValue.cityId);
                          setStudentData({
                            ...studentData,
                            ["city"]: newValue,
                          });
                        }
                      } else {
                        setCityValue(newValue);
                        setCityID(1);
                        setStudentData({ ...studentData, ["city"]: "" });
                      }
                      setBranchList([]);
                    }}
                    
                    value={
                      studentData.city === undefined
                        ? null
                        : studentData.city.cityName
                    }
                    onOpen={loadCityData}
                    options={cityData}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }

                      return option.cityName;
                    }}
                    getOptionSelected={(option) =>
                      option.value === cityList.cityName
                    }
                    renderOption={(option) => option.cityName}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        
                        name="city"
                        className={
                          (errors.city && classes.invalid) ||
                          classes.formControl
                        }
                        label={fieldsLabels.city}
                        helperText="City"
                        size="small"
                        variant="outlined"
                        disabled={!studentEditPermissionFlag}
                      />
                    )}
                  />
                )}
                disabled={!studentEditPermissionFlag}
                name="city"
                control={control}
                rules={{ required: true }}
                defaultValue={{
                  cityName:
                    studentData.city === undefined
                      ? null
                      : studentData.city.cityName,
                  cityId:
                    studentData.city === undefined
                      ? ""
                      : studentData.city.cityId,
                }}
              />

              {_.get("city.type", errors) === "required" && (
                <FormHelperText className={classes.error}>
                  Please select the city
                </FormHelperText>
              )}
            </Grid>
            <br />
            <Grid item xs={12} className={classes.gridItem}>
              <Controller
                render={(props) => (
                  <Autocomplete
                  disableClearable
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                    style={{
                      marginLeft: smallScreen ? "0%" : "20%",
                      width: "60%",
                    }}
                    onChange={(event, newValue) => {
                      props.onChange(newValue);

                      if (typeof newValue === "string") {
                        if (newValue != null) {
                          setBranchValue({
                            newValue,
                          });
                          setStudentData({
                            ...studentData,
                            ["branch"]: newValue,
                          });
                        }
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setBranchValue({
                          title: newValue.inputValue,
                        });
                        if (newValue != null) {
                          setStudentData({
                            ...studentData,
                            ["branch"]: newValue.inputValue,
                          });
                        }
                      } else if (newValue != null) {
                        setBranchValue(newValue);
                        if (newValue) {
                          setStudentData({
                            ...studentData,
                            ["branch"]: newValue,
                          });
                        }
                      } else {
                        setBranchValue(newValue);
                        setStudentData({ ...studentData, ["branch"]: "" });
                      }
                    }}
                    
                    value={
                      studentData.branch === undefined
                        ? null
                        : studentData.branch.name
                    }
                    onOpen={loadBranchData}
                    options={branchData}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.name;
                    }}
                    getOptionSelected={(option) =>
                      option.value === branchList.name
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        
                        className={
                          (errors.branch && classes.invalid) ||
                          classes.formControl
                        }
                        label={fieldsLabels.branch}
                        helperText="Branch"
                        size="small"
                        variant="outlined"
                        disabled={!studentEditPermissionFlag}
                      />
                    )}
                  />
                )}
                name="branch"
                disabled={!studentEditPermissionFlag}
                control={control}
                rules={{ required: true }}
                defaultValue={{
                  name:
                    studentData.branch === undefined
                      ? null
                      : studentData.branch.name,
                  branchId:
                    studentData.branch === undefined
                      ? 1
                      : studentData.branch.branchId,
                }}
              />
              {_.get("branch.type", errors) === "required" && (
                <FormHelperText className={classes.error}>
                  Please select the coordinating center
                </FormHelperText>
              )}
            </Grid>
          </TabPanel>
          {/* --------------------------------------------------------------SIP Info------------------------------------------------- */}
          <TabPanel value={value} index={0} dir={theme.direction}>
            <Grid
              item
              xs={12}
              className={classes.gridItemSelect}
              style={{ marginLeft: smallScreen ? "0%" : "20%" }}
            >
              <FormControl
                fullWidth
                size="small"
                variant="outlined"
                className={
                  (errors.sipAbacusStudent && classes.invalidSelect) ||
                  classes.formControlSelect
                }
              >
                <InputLabel>{fieldsLabels.sipAbacusStudent}</InputLabel>
                <Controller
                  render={(props) => (
                    <Select
                      name="sipAbacusStudent"
                      value={studentData.sipAbacusStudent}
                      label={fieldsLabels.sipAbacusStudent}
                      onBlur={(e) => {
                        props.onBlur(e);
                      }}
                      onChange={(e) => {
                        props.onChange(e);
                        setEmptySipAbacusStudent(false);
                        setStudentData({
                          ...studentData,
                          ["sipAbacusStudent"]: getValues("sipAbacusStudent"),
                        });
                      }}
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Select>
                  )}
                  name="sipAbacusStudent"
                  control={control}
                  defaultValue={studentData.sipAbacusStudent}
                  rules={{ required: true }}
                ></Controller>
              </FormControl>
              {_.get("sipAbacusStudent.type", errors) === "required" && (
                <FormHelperText className={classes.error}>
                  Please select the Yes/No
                </FormHelperText>
              )}
              {_.get("sipAbacusStudent.type", errors) !== "required" && (
                <FormHelperText style={{ color: "darkslategrey" }}>
                  SIP Abacus Student
                </FormHelperText>
              )}
            </Grid>
            <br />
            <Grid
              item
              xs={12}
              className={classes.gridItemSelect}
              justify="center"
              style={{ marginLeft: smallScreen ? "0%" : "20%" }}
            >
              <FormControl fullWidth size="small" variant="outlined">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    className={classes.workshopDate}
                    size="small"
                    id="workshopDate"
                    label={fieldsLabels.workShopAttendedDate}
                    format="dd-MM-yyyy"
                    inputVariant="outlined"
                    inputRef={register({
                      required: false,
                    })}
                    defaultValue={studentData.workShopAttendedDate}
                    value= {attendedDate}
                    onChange={(date) => {
                      if (date === null || date === undefined) {
                             setWorkshopDate(new Date(currentDateAndTimeFromServer));
                             setWorkshopAttendedDate(new Date(currentDateAndTimeFromServer));
                            }
                          else{
                               setWorkshopAttendedDate(date); setWorkshopDate(date);
                          }
                   
                    }}
                    helperText="Please enter workshop attended date"
                    InputLabelProps={{ shrink: true }}
                    name="workShopDate"
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>
            <span style={{ fontWeight: "bold", color: "red" }}>
              Please add workshop attended date only after exam is completed and
              workshop is scheduled.
            </span>
          </TabPanel>
        </form>
      </DialogContent>
      <DialogActions className={classes.actionButtons}>
        <Button
          variant="outlined"
          size={"small"}
          color="secondary"
          onClick={handleClose}
          endIcon={<CancelOutlinedIcon />}
        >
          Cancel
        </Button>
        {/* <Button variant="outlined" size={"small"} disabled={resetButtonStatus}
                    endIcon={<ResetOutlinedIcon />} onClick={() => {
                        reset({
                            name: "",
                            organization: "",
                            branch: ""
                        }
                        );
                    }}>Reset</Button> */}
        <Button
          variant="outlined"
          size={"small"}
          color="primary"
          disabled={
            updateButtonStatus ||
            emptySipAbacusStudent ||
            isUpdateButtonDisabled
          }
          onClick={handleSubmit(onSubmit)}
          endIcon={<SaveOutlinedIcon />}
        >
          {buttonLabel}
        </Button>
        {isUpdateButtonDisabled && <CircularProgress size={24} />}
      </DialogActions>
    </Dialog>
  );
}

EditStudent.propTypes = {
  open: PropTypes.bool.isRequired,
  studentObject: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};
