import React, { useState, useEffect } from "react";
import {
  FormControl,
  Grid,
  Paper,
  TextField,
  makeStyles,
  useTheme,
  useMediaQuery,
  Typography,
} from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import axios from "axios";
import { LoggedInUserDetails } from "../LoggedInUserDetails/LoggedInUserDetails";
import {
  LineChart,
  ResponsiveContainer,
  Legend,
  Tooltip,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";
import { color } from "@mui/system";
import DashboardBg from "./DashboardBg.png";
import DashboardMobileBg from "./DashboardMobileBg.png";
//import MobileDatePicker from "@mui/lab/MobileDatePicker";
//import LocalizationProvider from "@mui/lab/LocalizationProvider";
//import AdapterDateFns from "@mui/lab/AdapterDateFns";
//import { MobileDatePicker,AdapterDateFns, LocalizationProvider} from "@mui/lab";
//import { AdapterDayjs } from '@mui/lab/AdapterDateFns';
//import { LocalizationProvider } from 'mui/lab';
//import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
///import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
//import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from "dayjs";
const useStyles = makeStyles((theme) => ({
  gridItem: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1, 5, 0, 5),
  },
  text: {
    fontFamily: "FilsonSoftW03-Bold",
    fontWeight: "bold",
    fontSize: "22px",
  },
  mobiletext: {
    fontFamily: "FilsonSoftW03-Bold",
    fontWeight: "bold",
    fontSize: "14px",
  },
  classCombo: {
    width: "20%",
  },
  emptyGrid: {
    marginLeft: "10%",
  },
  paperImage: {
    backgroundImage: `url(${DashboardBg})`,
    height: "112vh",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100vw",
  },
  image: {
    height: "112vh",
    backgroundImage: `url(${DashboardBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100vw",
  },
  mobileImage: {
    height: "150vh",
    backgroundImage: `url(${DashboardMobileBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  scoreText: {
    fontFamily: "FilsonSoftW03-Bold",
    fontWeight: "bold",
    fontSize: "14px",
    marginLeft: "5%",
    color:"red"
  },
  scoreTextMobile: {
    fontFamily: "FilsonSoftW03-Bold",
    fontWeight: "bold",
    fontSize: "14px",
    marginLeft: "20%",
    color:"red"
  }, scoreText1: {
    fontFamily: "FilsonSoftW03-Bold",
    fontWeight: "bold",
    fontSize: "14px",
    marginLeft: "80%",
    marginTop:"-6%",
    color:"green"
  },scoreText2: {
    fontFamily: "FilsonSoftW03-Bold",
    fontWeight: "bold",
    fontSize: "14px",
    marginLeft: "80%",
    marginTop:"1%",
    color:"green"
  },
  scoreTextMobile1: {
    fontFamily: "FilsonSoftW03-Bold",
    fontWeight: "bold",
    fontSize: "14px",
    marginLeft: "20%",
     color:"green"
  },oneLevelScore:{
    fontFamily: "FilsonSoftW03-Bold",
    fontWeight: "bold",
    fontSize: "14px",
    marginLeft: "5%",
    marginTop:"1%",
     color:"red"
  },oneLevelScore1:{
    fontFamily: "FilsonSoftW03-Bold",
    fontWeight: "bold",
    fontSize: "14px",
    marginLeft: "5%",
    marginTop:"1%",
    color:"red"
  }
}));

export default function GraphAnalysis(props) {
  const classes = useStyles();
  const [classList, setClassList] = useState([]);
  const [attemptList, setAttemptList] = useState([]);
  const BASE_URL = `${process.env.REACT_APP_API_URL}`;
  const { loggedInUserInfo, setLoggedInUserInfo } =
    React.useContext(LoggedInUserDetails);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [classId, setClassId] = React.useState(loggedInUserInfo.classId);
  const [selectedclassId, setSelectedClassId] = React.useState(0);
  let theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [tdata, setTdata] = useState([]);
  const [graphtdata, setGraphdata] = React.useState([]);
  let colorChanged = false;
  let color = "blue";
  let color2 = "";
  const refresh = props.refresh;
  let changedLevelMarks = undefined;
  const [firstScoreList, setFirstScoreList] = useState([]);
  const [bestScoreList, setBestScoreList] = useState([]);
  useEffect(() => {
    loadClassData();
  }, []);

  const loadClassData = (searchedEntityCode) => {
    axios
      .get(BASE_URL + `/master/class/list`, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("authorization"),
        },
      })
      .then((response) => {
        setClassList(response.data.responseData);
      });
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  useEffect(() => {
    if (loggedInUserInfo.studentId > 0 && loggedInUserInfo.classId > 0) {
      axios
        .get(
          BASE_URL + `/examinationParticipation/examParticipation/attempts`,
          {
            headers: {
              authorization: "Bearer " + localStorage.getItem("authorization"),
            },
            params: {
              studentId: loggedInUserInfo.studentId,
              classId: selectedclassId == undefined ? 0 : selectedclassId,
              month: smallScreen
                ? selectedDate.$M + 1
                : selectedDate.getMonth() + 1,
              year: smallScreen
                ? selectedDate.$y
                : selectedDate.getYear() + 1900,
            },
          }
        )
        .then((response) => {
          setAttemptList(response.data.responseData);
          setFirstScoreList(response.data.firstScore);
          setBestScoreList(response.data.bestScore);
        });
    }
  }, [selectedDate, loggedInUserInfo.studentId, classId, selectedclassId]);
  console.log(firstScoreList)
        console.log(firstScoreList)
  return (
    <>
      <Paper>
        <h1 className={smallScreen ? classes.mobiletext : classes.text}>
          Practice Score Analysis
        </h1>
        {smallScreen ? (
          <>
            <Grid className={classes.emptyGrid}></Grid>
            <>
      
              {<MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/yyyy"
                  views={["year", "month"]}
                  margin="normal"
                  id="date-picker-inline"
                  label="Choose Month"
                  value={selectedDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
                </MuiPickersUtilsProvider>}
            </>
          </>
        ) : (
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/yyyy"
                    views={["year", "month"]}
                    margin="normal"
                    id="date-picker-inline"
                    label="Choose Month"
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        )}
        <h4
          className={smallScreen ? classes.scoreTextMobile : firstScoreList.FirstScoreLevel1 > 0? classes.scoreText : classes.oneLevelScore}
        >
          First Score of Level:{firstScoreList.FirstScoreLevel0}
        </h4>
        <h4
          className={smallScreen ? classes.scoreTextMobile : firstScoreList.FirstScoreLevel1 > 0? classes.scoreText : classes.oneLevelScore1}
        >
          Best Score of Level:{bestScoreList.BestScoreLevel0}
        </h4>
        
        {firstScoreList.FirstScoreLevel1 != null ? (
          <>
            <h4
              className={ 
                smallScreen ? classes.scoreTextMobile1 : classes.scoreText1
              }
            >
              First Score of New Level:{firstScoreList.FirstScoreLevel1}
            </h4>
            <h4
              className={
                smallScreen ? classes.scoreTextMobile1 : classes.scoreText2
              }
            >
              Best Score of New Level:{bestScoreList.BestScoreLevel1}
            </h4>
          </>
        ) : (
          ""
        )}
        <ResponsiveContainer
          width={smallScreen ? 450 : "100%"}
          height="100%"
          aspect={smallScreen ? 1 : 3}
        >
          <LineChart
            data={
              attemptList.length > 0
                ? attemptList.map((item, index, arr) => {
                    if (
                      index > 0 &&
                      !colorChanged &&
                      arr[index].classId == arr[index - 1].classId
                    ) {
                      color = "red";
                    } else if (index == 0) {
                      color = "red";
                    } else {
                      color2 = "green";
                      colorChanged = true;
                      changedLevelMarks = item.marks;
                    }
                    return {
                      name: item.attempts,
                      marks:
                        index > 0 &&
                        !colorChanged &&
                        arr[index].classId == arr[index - 1].classId
                          ? item.marks
                          : index == 0
                          ? item.marks
                          : undefined,
                      classId: item.classId,
                      nextLevelMarks: changedLevelMarks,
                    };
                  })
                : ""
            }
            margin={{ right: 100, bottom: 70 }}
          >
            <CartesianGrid />
            <XAxis
              style={{ fontWeight: "bold" }}
              label={{
                value: "Practice Score Trend",
                position: "insideBottom",
                dy: 40,
                fontSize: smallScreen ? 16 : 30,
                fill: "blue",
              }}
              interval={smallScreen ? 5 : 0}
            />
            <YAxis
              style={{ fontWeight: "bold" }}
              ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            />
            <Tooltip />
            <Legend layout="horizontal" verticalAlign="top" align="center" />
            <Line
              name="CurrentLevelMarks"
              dataKey="marks"
              strokeWidth={1.5}
              stroke={color}
              dot={true}
            />
            {
              <Line
                name="NextLevelChange"
                dataKey="nextLevelMarks"
                strokeWidth={1.5}
                stroke={color2}
                dot={true}
              />
            }
          </LineChart>
        </ResponsiveContainer>
      </Paper>
    </>
  );
}
