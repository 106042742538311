import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, IconButton, InputAdornment, makeStyles, Slide, Snackbar, TextField, CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import _ from "lodash/fp";
import axios from 'axios';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    formControl: {
        // margin: theme.spacing(1),
    },
    actionButtons: {
        marginRight: theme.spacing(3),
    },
    error: {
        color: "red",
    },
    checkboxSize: {
        width: "18px",
        height: "18px"
    },
    onFocused: {
        borderColor: '#d4cfce !important',
    },
    disabledLabel: {
        color: '#d4cfce !important',
    }
}));

function ActivateTestDialog(props) {
    const classes = useStyles();
    const { onClose, open, selectedStudentFromTable, currentDateAndTimeFromServer, examGroupId,examinationId} = props;
    const dialogTitle = "Activate Test";
    const fieldLabels = {
        name: "Batch Name",
        exam: "Exam",
        testDate: "Test Date",
        startTime: "Start Time",
        endTime: "End Time",
        sendSms: "Send SMS",
        meetingLink: "Contest Link",
        smsTemplate: "SMS Template",
        helperTextToEnter: "Please enter the ",
        helperTextToSelect: "Please select the "
    };
    const BASE_URL = `${process.env.REACT_APP_API_URL}`;
    const buttonLabel = useState("Submit");
    const minRowsAllotedForMeetingLink = 6;
    const [branchId] = useState(JSON.parse(localStorage.getItem("sip-user")).branch.branchId);
    const [userName] = useState(JSON.parse(localStorage.getItem("sip-user")).userName);
    const [serverCurrentDateAndTime] = useState(new Date(currentDateAndTimeFromServer));
    const [testDate, setTestDate] = useState(new Date(serverCurrentDateAndTime));
    const [autogeneratedBatchName, setAutogeneratedBatchName] = useState();
    const { register, handleSubmit, errors, watch, reset, setValue, getValues } = useForm({
        mode: "all",
        defaultValues: {
            name: autogeneratedBatchName,
        }
    });
    const [state, setState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const { vertical, horizontal, openAlert, message, severity } = state;
    const validatedDate = testDate !== null ? testDate : new Date(currentDateAndTimeFromServer);
    const testDateMonth = (validatedDate.getMonth() + 1).toString().length === 1 ? ("0" + (validatedDate.getMonth() + 1).toString()) : (validatedDate.getMonth() + 1).toString();
    const selectedDate = validatedDate.getDate() + "-" + testDateMonth + "-" + validatedDate.getFullYear();
    const currentDate = new Date(currentDateAndTimeFromServer);
    const currentDateWithoutTimeStamp = new Date(currentDateAndTimeFromServer);
    const currentHours = currentDate.getHours();
    const currentHoursInStringFormat = ((currentHours + 1).toString().length === 1 ? ("0" + (currentHours + 1)) : (currentHours + 1).toString());
    const currentHoursInStringFormatStartTime = ((currentHours).toString().length === 1 ? ("0" + (currentHours)) : (currentHours).toString());
    const currentMinutes = currentDate.getMinutes().toString().length === 1 ? ("0" + currentDate.getMinutes().toString()) : (currentDate.getMinutes().toString());
    const defaultStartTime = currentHoursInStringFormatStartTime + ":" + currentMinutes;
    const defaultEndTime = currentHoursInStringFormat + ":" + currentMinutes;
    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);
    const [zoomLink, setZoomLink] = useState('');
    const [startTimeForValidation, setStartTimeForValidation] = useState(defaultStartTime);
    const [endTimeForValidation, setEndTimeForValidation] = useState(defaultEndTime);
    const [contestLinkActivation,setContestLinkActivation] = useState(false);

    const [isSubmitEnabled,setIsSubmitEnabled]=React.useState(false);
    useEffect(() => {
        examinationId ===1 ? setContestLinkActivation(false):setContestLinkActivation(true);
        setTestDate(new Date(currentDateAndTimeFromServer));
        setStartTimeForValidation(defaultStartTime);
        setEndTimeForValidation(defaultEndTime);
        axios.get(BASE_URL + "/SIPCustomApi/batchName/" + branchId,
            {
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem("sip-authorization")
                }
            }).then((response) => {
                setAutogeneratedBatchName(response.data);
            })
            .catch(function (error) {
            });
    }, [branchId, open])
    const template = examinationId ===1 ?( examGroupId == 4 ? `Congratulations! Your child's AGC State Finals is scheduled on
    ${selectedDate} at ${startTimeForValidation === undefined ? '__________' : startTimeForValidation}. Test Login Id - <Login-ID>. Other details will be 
    communicated soon. All the very best. Team - SIP Abacus` : `Congratulations! Your child's AGC National Finals is scheduled on
    ${selectedDate} at ${startTimeForValidation === undefined ? '__________' : startTimeForValidation}. Test Login Id - <Login-ID>. Other details will be 
    communicated soon. All the very best. Team - SIP Abacus` ):(`Dear Parent, The NATIONAL ROUND of SIP National Prodigy 2022 for your child is scheduled on ${selectedDate} at 
    ${startTimeForValidation === undefined ? '__________' : startTimeForValidation}.  Your Child's Login ID and Password shall remain the same.
     Other details will be communicated soon. All the very best! Team SIP Abacus`)

    const [isStartAndEndTimeSame, setIsStartAndEndTimeSame] = useState(false);
    const [isStartTimeIsGreaterThanEndTime, setIsStartTimeIsGreaterThanEndTime] = useState(false);
    const [isStartTimeIsSmallerThanCurrentTime, setIsStartTimeIsSmallerThanCurrentTime] = useState(false);


    const resetState = () => {
        setTestDate(new Date());
        setIsSubmitButtonDisabled(false);
        setIsStartAndEndTimeSame(false);
        setStartTimeForValidation(defaultStartTime);
        setEndTimeForValidation(defaultEndTime);
        setIsStartTimeIsGreaterThanEndTime(false);
        setIsSubmitEnabled(false)
    }

    const handleClose = () => {
        resetState();
        onClose(false);
    }

    const handleAlertClose = () => {
        setState({ ...state, openAlert: false });
    };

    const handleEntry=()=>{
        setIsSubmitEnabled(false)
    }

    useEffect(()=>{
        if(isSubmitEnabled){
            setIsSubmitButtonDisabled(isSubmitEnabled);
        }

    },[isSubmitEnabled])

    useEffect(() => {
        let testDateWithOutTimeStamp;
        if (testDate !== null) {
            testDateWithOutTimeStamp = new Date(testDate.getFullYear(), testDate.getMonth(), testDate.getDate());
        }
        var splittedStartTime = startTimeForValidation.split(':');
        var splittedEndTime = endTimeForValidation.split(':');
        if (!isNaN(testDate) === false) {
            setIsSubmitButtonDisabled(true);
        } else if (splittedStartTime[0] === splittedEndTime[0] && splittedStartTime[1] === splittedEndTime[1]) {
            setIsStartAndEndTimeSame(true);
            setIsSubmitButtonDisabled(true);
        } else if (testDateWithOutTimeStamp.getTime() < currentDateWithoutTimeStamp.getTime()
            && (Number(splittedStartTime[0]) < currentHours || (Number(splittedStartTime[0]) <= currentHours &&
                Number(splittedStartTime[1]) < Number(currentMinutes)))) {
            setIsStartTimeIsSmallerThanCurrentTime(true);
            setIsStartAndEndTimeSame(false);
            setIsStartTimeIsGreaterThanEndTime(false);
            setIsSubmitButtonDisabled(true);
        } else if (((Number(splittedStartTime[0]) > Number(splittedEndTime[0])) 
        || (Number(splittedStartTime[0]) >= Number(splittedEndTime[0]) && Number(splittedStartTime[1]) >= Number(splittedEndTime[1])))) {
            setIsStartTimeIsSmallerThanCurrentTime(false);
            setIsStartAndEndTimeSame(false);
            setIsStartTimeIsGreaterThanEndTime(true);
            setIsSubmitButtonDisabled(true);
        } else {
            setIsStartTimeIsSmallerThanCurrentTime(false);
            setIsStartTimeIsGreaterThanEndTime(false);
            setIsStartAndEndTimeSame(false);
            setIsSubmitButtonDisabled(false);
        }
    }, [testDate, startTimeForValidation, endTimeForValidation])

    const onSubmit = data => {
        setIsSubmitEnabled(true)
        let date = data.startDate;
        data["endDate"] = data.startDate + " " + data.endTime;
        data["createdBy"] = userName;
        data["lastUpdatedBy"] = userName;
        data["active"] = true;
        data["branch"] = { 'branchId': branchId };
        data["studentList"] = selectedStudentFromTable;
        data["startDate"] = date + " " + data.startTime;
        data["smsTemplate"] = { template };
        data["examGroupId"] = examGroupId;

        for (let studentObject = 0; studentObject < selectedStudentFromTable.length; studentObject++) {
            selectedStudentFromTable[studentObject].studentAccount = null
            selectedStudentFromTable[studentObject].createdDt = null;
            selectedStudentFromTable[studentObject].lastUpdatedDt = null;
            selectedStudentFromTable[studentObject].workshop = null;
            selectedStudentFromTable[studentObject].schoolName =null;
        }
        axios.post(BASE_URL + "/SIPCustomApi/batches", data,
            {
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem("sip-authorization")
                }
            }).then((response) => {
                resetState();
                setIsSubmitEnabled(true)
                onClose(true);
            })
            .catch(function (error) {
                setIsSubmitEnabled(false)
                setState({ ...state, openAlert: true, message: 'Failed to activate the test!', severity: "error" });
            });

    }

    return (
        <Dialog fullWidth="true" onClose={handleClose} aria-labelledby="activate-test"
            open={open} TransitionComponent={Transition} disableBackdropClick={true} onEntering={handleEntry}>
            <DialogTitle className={classes.dialogTitleContainer}>
                {dialogTitle}
            </DialogTitle>
            <DialogContent dividers className={classes.formControl} style={{ marginTop: "-2%" }}>
                <Snackbar
                    anchorOrigin={{ vertical, horizontal }}
                    open={openAlert}
                    onClose={handleAlertClose}
                    key={vertical + horizontal}
                    autoHideDuration={6000}
                >
                    <Alert onClose={handleAlertClose} severity={severity}>
                        {message}
                    </Alert>
                </Snackbar>
                <form onSubmit={handleSubmit(onSubmit)}>

                    <FormControl fullWidth>
                        <TextField
                            label={fieldLabels.name + " *"}
                            variant="outlined"
                            name="name"
                            size="small"
                            inputRef={register({
                                required: true,
                                maxLength: 100,
                            })}
                            InputProps={{
                                readOnly: true,
                                classes: {
                                    focused: classes.onFocused,
                                    notchedOutline: classes.onFocused
                                }
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.disabledLabel,
                                    focused: classes.cssFocused,
                                },
                            }}
                            fullWidth
                            defaultValue={autogeneratedBatchName}
                            autoComplete="false"
                        ></TextField>
                        <Grid container>
                            <Grid item xs={6} md={6} lg={6}>
                                <FormHelperText>AutoGenerated Batch Name</FormHelperText>
                            </Grid>
                        </Grid>
                        <div className={classes.error}>
                            {_.get("name.type", errors) === "required" && (
                                <FormHelperText className={classes.error}>{fieldLabels.name} is required</FormHelperText>
                            )}
                            {_.get("name.type", errors) === "maxLength" && (
                                <FormHelperText className={classes.error}>{fieldLabels.name} cannot exceed 100 characters</FormHelperText>
                            )}
                        </div>
                    </FormControl>

                    <Grid container spacing={2} style={{ marginTop: "0.5%" }}>
                        <Grid item xs={12} md={4} lg={4}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <FormControl fullWidth>
                                    <KeyboardDatePicker
                                        autoFocus
                                        size="small"
                                        id="start-date"
                                        label={fieldLabels.testDate + " *"}
                                        format="dd-MM-yyyy"
                                        inputVariant="outlined"
                                        minDate={new Date(currentDateAndTimeFromServer)}
                                        inputRef={register({
                                            required: true,
                                        })}
                                        value={testDate}
                                        invalidDateMessage={`Please select valid date.`}
                                        minDateMessage= {`Date cannot be less than today.`}
                                        onChange={((date) => {
                                            if(date === null || date === undefined) {
                                                setTestDate(new Date(currentDateAndTimeFromServer));
                                            } else {
                                                setTestDate(date);
                                            }
                                        })}
                                        InputLabelProps={{ shrink: true }}
                                        name="startDate"
                                    />
                                    <FormHelperText>{fieldLabels.helperTextToSelect}{fieldLabels.testDate}</FormHelperText>
                                    <div className={classes.error}>
                                        {_.get("startDate.type", errors) === "required" && testDate === null && (
                                            <FormHelperText className={classes.error}>{fieldLabels.testDate} is required</FormHelperText>
                                        )}
                                    </div>
                                </FormControl>
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                            <FormControl fullWidth>
                                <TextField
                                    id="start-time"
                                    size="small"
                                    label={fieldLabels.startTime + " *"}
                                    type="time"
                                    defaultValue={defaultStartTime}
                                    className={classes.textField}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        step: 300, // 5 min
                                    }}
                                    inputRef={register({
                                        required: true,
                                    })}
                                    name="startTime"
                                    onChange={((event) => {
                                        setStartTimeForValidation(event.target.value);
                                    })}
                                />
                                <FormHelperText>{fieldLabels.helperTextToSelect}{fieldLabels.startTime}</FormHelperText>
                                <div className={classes.error}>
                                    {_.get("startTime.type", errors) === "required" && (
                                        <FormHelperText className={classes.error}>{fieldLabels.startTime} is required</FormHelperText>
                                    )}
                                    {startTimeForValidation === "" && (
                                        <FormHelperText className={classes.error}>{fieldLabels.endTime} is inValid</FormHelperText>
                                    )}
                                    {isStartTimeIsSmallerThanCurrentTime && (
                                        <FormHelperText className={classes.error}>{fieldLabels.startTime} is smaller than current time</FormHelperText>
                                    )}
                                </div>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                            <FormControl fullWidth>
                                <TextField
                                    id="end-time"
                                    size="small"
                                    label={fieldLabels.endTime + " *"}
                                    type="time"
                                    defaultValue={defaultEndTime}
                                    className={classes.textField}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        step: 300, // 5 min
                                    }}
                                    inputRef={register({
                                        required: true,
                                    })}
                                    name="endTime"
                                    onChange={((event) => {
                                        setEndTimeForValidation(event.target.value);
                                    })}
                                />
                                <FormHelperText>{fieldLabels.helperTextToSelect}{fieldLabels.endTime}</FormHelperText>
                                <div className={classes.error}>
                                    {_.get("endTime.type", errors) === "required" && (
                                        <FormHelperText className={classes.error}>{fieldLabels.endTime} is required</FormHelperText>
                                    )}
                                    {endTimeForValidation === "" && (
                                        <FormHelperText className={classes.error}>{fieldLabels.endTime} is inValid</FormHelperText>
                                    )}
                                    {isStartAndEndTimeSame && (
                                        <FormHelperText className={classes.error}>{fieldLabels.endTime} and {fieldLabels.startTime} are same</FormHelperText>
                                    )}
                                    {isStartTimeIsGreaterThanEndTime && (
                                        <FormHelperText className={classes.error}>{fieldLabels.endTime} should not be less than {fieldLabels.startTime}</FormHelperText>
                                    )}
                                </div>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} style={{ marginTop: "1%" }} justify="flex-end">
                        {/* <Grid item xs={12} md={4} lg={4}>
                            <FormControl>
                                <Grid container justify="flex-start" alignItems="center">
                                    <input className={classes.checkboxSize}
                                        ref={register}
                                        name="sendSms"
                                        type="Checkbox"
                                    /> <span style={{ marginLeft: "10px" }}> {fieldLabels.sendSms} </span>
                                </Grid>
                            </FormControl>
                        </Grid> */}
                        <Grid item xs={12} md={8} lg={8}>
                            
                        </Grid>
                    </Grid>

                    <FormControl fullWidth style={{ marginTop: "2%" }}>
                        <TextField
                            label={fieldLabels.smsTemplate}
                            inputProps={{ min: 0, style: { textAlign: 'justify', color: 'grey' } }}
                            variant="outlined"
                            value={template}
                            InputProps={{
                                readOnly: true,
                                classes: {
                                    focused: classes.onFocused,
                                    notchedOutline: classes.onFocused
                                }
                            }}
                            InputLabelProps={{
                                classes: {
                                    focused: classes.cssFocused,
                                    root: classes.disabledLabel,
                                },
                            }}
                            name="smsTemplate"
                            size="small"
                            inputRef={register}
                            fullWidth
                            autoComplete="false"
                            multiline
                            rows={minRowsAllotedForMeetingLink}
                        ></TextField>
                    </FormControl>

                </form>
            </DialogContent>
            <DialogActions className={classes.actionButtons}>
                <Button variant="outlined" size={"small"} color="secondary" onClick={handleClose}
                    endIcon={<CancelOutlinedIcon />}>Cancel</Button>

                <Button  variant="outlined" size={"small"} color="primary" disabled={isSubmitButtonDisabled}
                    onClick={handleSubmit(onSubmit)} endIcon={<SaveOutlinedIcon />}>{buttonLabel}</Button>
                                            {isSubmitEnabled && <CircularProgress size={24} />}


            </DialogActions>
        </Dialog >
    );
}

ActivateTestDialog.prototype = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    selectedStudentFromTable: PropTypes.object.isRequired
}

export default ActivateTestDialog;