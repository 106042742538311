import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent, TextField, Button, FormControl, FormHelperText, Grid,  } from '@material-ui/core';
import { useForm, Controller } from "react-hook-form";
import Select from 'react-select'
import _ from "lodash/fp";
import PropTypes from 'prop-types';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import ResetOutlinedIcon from '@material-ui/icons/LoopOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    error: {
        color: "red",
        fontSize: "14px"
    },
    margin: {
        marginTop: theme.spacing(1),
    },
    titleAlignment: {
        marginBottom: "1px",
    },
    actionButtons: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3)
    },
    checkboxSize: {
        width: "18px",
        height: "18px"
    },
    dialogTitleContainer: {
        height: "30px",
    }
}));

export default function CreateOrEditSubject(props) {

    const classes = useStyles();
    const { onClose, subjectObject, open } = props;
    const [title, setTitle] = useState("Create");
    const [orgId, setOrgId] = useState(0);
    const [buttonLabel, setButtonLabel] = useState("Save");
    const [resetButtonStatus, setRestButtonStatus] = useState(false);
    const [isOrgOptionsLoading, setIsOrgLoadingOptions] = useState(false);
    const [isBranchOptionsLoading, setIsBranchLoadingOptions] = useState(false);
    const [organizationList, setOrganizationList] = useState([]);
    const [branchList, setBranchList] = useState([]);
    const [nameFieldLength, setNameFieldLength] = useState(0);
    const [codeFieldLength, setCodeFieldLength] = useState(0);
    const [descFieldLength, setDescFieldLength] = useState(0);
    const { control, register, handleSubmit, errors, watch, reset, setValue, getValues } = useForm({
        defaultValues: {
            name: "",
        }
    });

    const BASE_URL = `${process.env.REACT_APP_API_URL}`;
    const username = 'admin';
    const password = 'admin';

    const onSubmit = data => {
        data["subjectId"] = `${subjectObject.subject.subjectId !== undefined ? subjectObject.subject.subjectId : 0}`;
        data["lastUpdatedDt"] = `${subjectObject.subject.lastUpdatedDt !== undefined ? subjectObject.subject.lastUpdatedDt : ""}`;
        data["lastUpdatedBy"] = `${subjectObject.subject.lastUpdatedBy !== undefined ? subjectObject.subject.lastUpdatedBy : ""}`;
        data["createdDt"] = `${subjectObject.subject.createdDt !== undefined ? subjectObject.subject.createdDt : ""}`;
        data["createdBy"] = `${subjectObject.subject.createdBy !== undefined ? subjectObject.subject.createdBy : ""}`;
        axios.post(BASE_URL + "/subjects", data,
            {
                headers: {
                    authorization: 'Basic ' + window.btoa(username + ":" + password)
                }
            }).then((Response) => {
                handleClose();
            })
            .catch(function (error) {
            });
    }

    useEffect(() => {
        if (subjectObject.mode === 'create') {
            setTitle("Create");
            setButtonLabel("Save");
        } else {
            setTitle("Edit");
            reset({
                subjectId: `${subjectObject.subject.subjectId !== undefined ? subjectObject.subject.subjectId : 0}`,
                name: `${subjectObject.subject.name !== undefined ? subjectObject.subject.name : ""}`,
                code: `${subjectObject.subject.code !== undefined ? subjectObject.subject.code : ""}`,
                description: `${subjectObject.subject.description !== undefined ? subjectObject.subject.description : ""}`,
                organization: {
                    value: subjectObject.subject.organization.organizationId,
                    label: subjectObject.subject.organization.name,
                    organizationId: subjectObject.subject.organization.organizationId
                },
                branch: {
                    value: subjectObject.subject.branch.branchId,
                    label: subjectObject.subject.branch.name,
                    branchId: subjectObject.subject.branch.branchId
                },
                active: subjectObject.subject.active ? true : false
            });
            setNameFieldLength(subjectObject.subject.name.length);
            setCodeFieldLength(subjectObject.subject.code.length);
            setDescFieldLength(subjectObject.subject.description.length);
            setButtonLabel("Update");
            setRestButtonStatus(true);
        }
    }, [subjectObject]);

    const handleClose = () => {
        reset({
            name: "",
            organization: "",
            branch: ""
        });
        setRestButtonStatus(false);
        setNameFieldLength(0);
        setCodeFieldLength(0);
        setDescFieldLength(0);
        onClose();
    }

    const onNameChange = () => {
        var nameFieldValue = watch("name");
        setNameFieldLength(nameFieldValue.length);
    }

    const loadOrgOptions = () => {
        setIsOrgLoadingOptions(true);
        setOrganizationList([]);
        axios.get(BASE_URL + "/organizations",
            {
                params: {
                    body: JSON.stringify({ name: "" }),
                    sortKey: "name"
                },
                headers: {
                    authorization: 'Basic ' + window.btoa(username + ":" + password)
                }
            }).then((response) => {
                setOrganizationList(response.data.content);
            });
        setIsOrgLoadingOptions(false);
    }

    const loadBranchOptions = () => {
        setIsBranchLoadingOptions(true);
        setBranchList([]);

        axios.get(BASE_URL + "/organizations/" + orgId + " /branches",
            {
                params: {
                    body: JSON.stringify({ name: "" }),
                    sortKey: "name"
                },
                headers: {
                    authorization: 'Basic ' + window.btoa(username + ":" + password)
                }
            }).then((response) => {
                setBranchList(response.data.content);
            });

        setIsBranchLoadingOptions(false);
    }

    const orgOptions = organizationList.map((organization) => {
        return { label: organization.name, organizationId: organization.organizationId, value: organization.organizationId }
    });


    const branchOptions = branchList.map((branch) => {
        return { label: branch.name, branchId: branch.branchId, value: branch.branchId }
    });

    return (
        <Dialog fullWidth={true} onClose={handleClose} aria-labelledby="create-or-delete-dialog"
            open={open} disableBackdropClick={true} TransitionComponent={Transition}>
            <DialogTitle className={classes.dialogTitleContainer}>
                {title} Subject
            </DialogTitle>
            <DialogContent className={classes.formControl}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <p className={classes.titleAlignment}>Name *</p>
                    <TextField
                        variant="outlined"
                        autoFocus
                        name="name"
                        size="small"
                        onChange={onNameChange}
                        inputRef={register({
                            required: true,
                            maxLength: 100,
                        })}
                        fullWidth
                        defaultValue=""
                        autoComplete="false"
                    ></TextField>
                    <Grid container>
                        <Grid item xs={6}>
                            <FormHelperText>Max 100 Chars</FormHelperText>
                        </Grid>
                        <Grid container item xs={6} justify="flex-end">
                            <FormHelperText>{nameFieldLength}/100</FormHelperText>
                        </Grid>
                    </Grid>
                    <div className={classes.error}>
                        {_.get("name.type", errors) === "required" && (
                            <p>Name is required</p>
                        )}
                        {_.get("name.type", errors) === "maxLength" && (
                            <p>Name cannot exceed 100 characters</p>
                        )}
                    </div>

                    <p className={classes.titleAlignment}>Code *</p>
                    <TextField
                        variant="outlined"
                        name="code"
                        size="small"
                        inputRef={register({
                            required: true,
                            maxLength: 100,
                        })}
                        onChange={(() => {
                            setCodeFieldLength(getValues("code").length)
                        })}
                        fullWidth
                        defaultValue=""
                        autoComplete="false"
                    ></TextField>
                    <Grid container>
                        <Grid item xs={6}>
                            <FormHelperText>Max 100 Chars</FormHelperText>
                        </Grid>
                        <Grid container item xs={6} justify="flex-end">
                            <FormHelperText>{codeFieldLength}/100</FormHelperText>
                        </Grid>
                    </Grid>
                    <div className={classes.error}>
                        {_.get("code.type", errors) === "required" && (
                            <p>Code is required</p>
                        )}
                        {_.get("code.type", errors) === "maxLength" && (
                            <p>Code cannot exceed 100 characters</p>
                        )}
                    </div>

                    <p className={classes.titleAlignment}>Description </p>
                    <TextField
                        variant="outlined"
                        name="description"
                        size="small"
                        inputRef={register({
                            maxLength: 300
                        })}
                        fullWidth
                        onChange={(() => {
                            setDescFieldLength(getValues("description").length)
                        })}
                        multiline
                        rows={3}
                        defaultValue=""
                        autoComplete="false"
                    ></TextField>
                    <Grid container>
                        <Grid container item xs={12} justify="flex-end">
                            <FormHelperText>{descFieldLength}/300</FormHelperText>
                        </Grid>
                    </Grid>
                    <div className={classes.error}>
                        {_.get("description.type", errors) === "maxLength" && (
                            <p>Description cannot exceed 300 characters</p>
                        )}
                    </div>

                    <FormControl fullWidth >
                        <p className={classes.titleAlignment}>Organization *</p>
                        <Controller
                            name="organization"
                            as={Select}
                            isClearable
                            control={control}
                            options={orgOptions}
                            defaultValue=""
                            onMenuOpen={loadOrgOptions}
                            onMenuClose={(() => {
                                let organization = getValues("organization");
                                if (organization !== "" && organization !== null) {
                                    setOrgId(organization.organizationId);
                                } else {
                                    setOrgId(0);
                                }
                            })}
                            menuPosition="fixed"
                            menuPlacement="auto"
                            isLoading={isOrgOptionsLoading}
                            rules={{ required: true }}
                        />
                        {_.get("organization.type", errors) === "required" && (
                            <p className={classes.error}>Organization is required</p>
                        )}
                    </FormControl>

                    <FormControl fullWidth>
                        <p className={classes.titleAlignment}>Branch *</p>
                        <Controller
                            name="branch"
                            as={Select}
                            control={control}
                            isClearable
                            options={branchOptions}
                            isLoading={isBranchOptionsLoading}
                            onMenuOpen={loadBranchOptions}
                            rules={{ required: true }}
                            defaultValue=""
                            menuPosition="fixed"
                            menuPlacement="auto"
                        />
                        {_.get("branch.type", errors) === "required" && (
                            <p className={classes.error}>Branch is required</p>
                        )}
                    </FormControl>
                    <FormControl>
                        <Grid container justify="flex-start" >
                            <input className={classes.checkboxSize}
                                ref={register}
                                name="active"
                                type="Checkbox"
                            /> <span className={classes.titleAlignment}>Active</span>
                    </Grid>
                    </FormControl>

                </form>
            </DialogContent>
            <DialogActions className={classes.actionButtons}>
                <Button variant="outlined" size={"small"} color="secondary" onClick={handleClose}
                 endIcon={<CancelOutlinedIcon />}>Cancel</Button>
                <Button variant="outlined" size={"small"} disabled={resetButtonStatus}
                endIcon={<ResetOutlinedIcon />} onClick={() => {
                        reset({
                            name: "",
                            code: "",
                            description: "",
                            organization: "",
                            branch: ""
                        }
                        );
                    }}>Reset</Button>
                <Button variant="outlined" size={"small"} color="primary" onClick={handleSubmit(onSubmit)}
                endIcon={<SaveOutlinedIcon />}>{buttonLabel}</Button>
            </DialogActions>
        </Dialog >
    );
}

CreateOrEditSubject.propTypes = {
    open: PropTypes.bool.isRequired,
    subjectObject: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired
};