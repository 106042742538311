import React, { useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, makeStyles, Slide, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    actionButtons: {
        margin: theme.spacing(3),
        marginRight: "8px",
    }
}));

export default function ConfirmationDialog(props) {
    const classes = useStyles;
    const{ onClose, message, open } = props;
    const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = React.useState(false);
    const [label,setLabel] = React.useState('Yes');
   
    const handleNoClose = (value) => {
        onClose(value);
    }

    const handleYesClose = (value) => {
        setIsDeleteButtonDisabled(true);
        onClose(value);
    }

    const handleEntry=()=>{
        setIsDeleteButtonDisabled(false)
    }

    return(
        <Dialog onClose={handleNoClose} aria-labelledby="create-or-delete-dialog"
        open={open} disableBackdropClick={true} TransitionComponent={Transition} onEntering={handleEntry}>
            
            <DialogTitle> Confirmation </DialogTitle>
            <DialogContent>
                <p>{message}</p>
            </DialogContent>
            <DialogActions className={classes.actionButtons}>
                    <Button variant="outlined" color="secondary" onClick={(() => {
                        handleNoClose(false);
                    })}>No</Button>
                    <Button disabled={isDeleteButtonDisabled} variant="outlined" color="primary" onClick={(() => {
                        handleYesClose(true);
                    })}>{label}</Button>
                {isDeleteButtonDisabled && <CircularProgress size={24} />}
            </DialogActions>

        </Dialog>
    );
}

ConfirmationDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired,
}