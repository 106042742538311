import React, { useState } from 'react';

export const LoggedInUserDetails = React.createContext();

// export const LoggedInUserProvider = LoggedInUserDetails.Provider;
// export const LoggedInUserDetailsConsumer = LoggedInUserDetails.Consumer;

const LoggedInUserProvider = (props) => {

    const [loggedInUserInfo, setLoggedInUserInfo] = useState(() => {
        return {
           childName:'',
           studentId:0,
           orgId:0,
           classId:0,
           parentOrgId:0,
           noOfAttempts:0
        }
    });

    return (
        <LoggedInUserDetails.Provider value={{ loggedInUserInfo, setLoggedInUserInfo }}>
            {props.children}
        </LoggedInUserDetails.Provider>
    );

}

export default LoggedInUserProvider;