import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Dialog, DialogContent, DialogActions, DialogTitle, Button, Snackbar, CircularProgress, withStyles, TableCell } from '@material-ui/core';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
// import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import AssessmentTwoToneIcon from '@material-ui/icons/AssessmentTwoTone';import axios from 'axios';
import { Alert } from '@material-ui/lab';





const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#FFA500",
        color: theme.palette.common.white,
        padding: '3px',
    },
    body: {
        fontSize: 12,
        padding: '3px',
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 'auto',
        stickyHeader: 'true'
    },
    dialogBoxHeight: {
        '& .MuiDialog-paperFullWidth': {
            height: 'auto',
            overFlowY: 'hidden'
        }
    },
    tableBody: {
        '& .MuiTableBody-root': {
            display: 'tableRowGroup',
            height: 'auto',
            minWidth: 'auto',
            textAlign: 'center',
            maxHeight: 440
        }
    },
});



export default function PracticeExamHistory(props) {

    const [activeIndex, setActiveIndex] = React.useState(-1);

    const handleIconClick = (index) => {
        if (activeIndex === index) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    };

    const { onClose, open, studentId, studentName, groupId, examName } = props;
    const classes = useStyles();
    const handleClose = () => {
        setResultList([{ 'createdDt': 'Loading......' }])
        setDetailsResultList([{ 'createdDt': 'Loading......' }])
        setDataLoading(true)
        onClose(false);
    }
    const [dataLoading, setDataLoading] = React.useState(true);
    const [resultList, setResultList] = React.useState([{ 'createdDt': 'Loading......' }]);
    const [detailsResultList, setDetailsResultList] = React.useState([{ 'createdDt': 'Loading......' }]);
    const BASE_URL = `${process.env.REACT_APP_API_URL}`;
    const [state, setState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const { vertical, horizontal, openAlert, message, severity } = state;
    const handleAlertClose = () => {
        setState({ ...state, openAlert: false });
    };

    const handleEntry = () => {
        setResultList([{ 'createdDt': 'Loading......' }])
        setDetailsResultList([{ 'createdDt': 'Loading......' }])
        getResults();
    }

    const getResults = () => {
        setDataLoading(true);
        axios.get(BASE_URL + "/examinationParticipation/examParticipation/results/" + studentId
            ,
            {
                //params:{
                //    groupId:examName =='agc'? 1 : 4
                //},
                headers: {
                    authorization: "Bearer " + localStorage.getItem("authorization"),
                },
            })
            .then(function (response) {
                if (response && response.data && response.data.responseData.length > 0) {
                    setResultList(response.data.responseData);
                    setDetailsResultList(response.data.responseData);
                    setDataLoading(false)
                } else {
                    setDataLoading(false);
                    setResultList([]);
                    setDetailsResultList([]);
                }
            })
            .catch(function (error) {
                setState({ ...state, openAlert: true, message: 'Failed to Load The Results', severity: "error" });
            });
    }

    const [maxWidth] = React.useState('md');
    const heading = examName;


    const columns = [
        { id: '', minWidth: 1, fontSize: '10px', width: 1},
        { id: 'S.No', minWidth: 1, align: 'center', fontSize: '12px', width: 1, paddingRight: '1px' },
        { id: 'Test Attended Date', minWidth: 1, align: 'center', fontSize: '12px', width: 1, paddingLeft: '1px', paddingRight: '1px' },
        { id: 'Total Score/Test Status', minWidth: 3, align: 'center', fontSize: '12px', width: 3 },
        // {id:'Module Name',minWidth:80,align:'center',fontSize:'14px',width:80},
        { id: 'Sums Attended/Total Sums', minWidth: 3, align: 'center', fontSize: '12px', width: 3 },
        { id: 'Correct Sums', minWidth: 3, align: 'center', fontSize: '12px', width: 3 },
        // {id:'Status',minWidth:20,align:'center',fontSize:'14px',width:20}
    ]

    const details_Columns = [
        { id: 'Section', minWidth: 7, align: 'center', fontSize: '14px', width: 7 },
        { id: 'Total Sums', minWidth: 20, align: 'center', fontSize: '14px', width: 20 },
        { id: 'Sums Attended', minWidth: 20, align: 'center', fontSize: '14px', width: 20 },
        { id: 'Total Score', minWidth: 20, align: 'center', fontSize: '14px', width: 80 },
    ]



    return (

        <Dialog fullWidth className={classes.dialogBoxHeight} maxWidth={maxWidth} open={open}
            onClose={handleClose} aria-labelledby="form-dialog-title" onEntering={handleEntry}>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleAlertClose}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert onClose={handleAlertClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <DialogTitle id="form-dialog-title">{heading} - {studentName} <span style={{ fontSize: "75%" }}>{" (Last 10 Practice results will be displayed here)"}</span>
                {dataLoading && <CircularProgress size={24} />}

            </DialogTitle>
            <DialogContent style={{ padding: '0 3%', width: "auto" }}  >
                <TableContainer component={Paper}>

                    {/* Main Table */}
                    <Table className={classes.table} size='small' style={{ border: '2px solid orange' }} stickyHeader='true' aria-label="sticky table">
                        <TableHead>
                            <TableRow width='2px'>
                                {columns.map((column, index) => (
                                    <StyledTableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth, fontSize: column.fontSize }} width={column.width}>
                                        {column.id.split('/').map((item, subIndex, array) => {
                                            return (
                                                <>
                                                    {item}
                                                    {array.length > 1 && subIndex < (array.length - 1) && '/'}
                                                    {array.length > 1 && subIndex < (array.length - 1) && <br />}
                                                </>
                                            );
                                        })}
                                    </StyledTableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {resultList.length > 0 ?
                            <TableBody>
                                {resultList.map((row, index) => (
                                    <>
                                        <StyledTableRow key={index}>
                                        <StyledTableCell align="center" style={{flex:30}}>
                                        {row.examStatus !== 'aborted' && (<AssessmentTwoToneIcon style={{cursor: 'pointer'}}
                                                    onClick={() => handleIconClick(index)} />)}
                                        </StyledTableCell>
                                            <StyledTableCell align="center">                                         
                                                {++index}
                                            </StyledTableCell>
                                            <StyledTableCell align="center" >{row.startDtDisp}</StyledTableCell>
                                            <StyledTableCell align="center">
                                                {row.marksScored}
                                                <br />
                                                <span style={{ color: row.examStatus === 'completed' ? 'green' : 'red', fontWeight: 'bold' }}>
                                                    {row.examStatus === 'completed' ? 'Completed' : 'Aborted'}
                                                </span>
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {row.examStatus === 'aborted' ? 'Nil' : `${row.answeredQuestions}/${row.totalQuestions}`}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {row.examStatus === 'aborted' ? 'Nil' : `${row.correctAnswered}`}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        
                                        {activeIndex === index && (
                                            <>
                                            <StyledTableRow key={index} style={{ backgroundColor: 'orange'}} >
                                                <StyledTableCell align="center" ></StyledTableCell>
                                                <StyledTableCell align="center" ><b>SECTION</b></StyledTableCell>
                                                <StyledTableCell align="center" ><b>MARKS PER QUESTION</b></StyledTableCell>
                                                <StyledTableCell align="center" ><b>SUMS ATTENDED</b></StyledTableCell>
                                                <StyledTableCell align="center" ><b>CORRECT SUMS</b></StyledTableCell>
                                                <StyledTableCell align="center" ><b>MARKS SCORED</b></StyledTableCell>
                                            </StyledTableRow>
                                            
                                            {row.sectionList.map((value,sctionIndex)=>{
                                                return(  <StyledTableRow key={sctionIndex} style={{ backgroundColor: '#ffffcc', horizontal: 'middle' }}>
                                                <StyledTableCell align="center" ></StyledTableCell>
                                                <StyledTableCell align="center" >{value.sectionName?value.sectionName:'Nil'}</StyledTableCell>
                                                <StyledTableCell align="center" >{value.marksPerQuestion?value.marksPerQuestion:'Nil'}</StyledTableCell>
                                                <StyledTableCell align="center" >{value.attendedQuestion?value.attendedQuestion:'Nil'}</StyledTableCell>
                                                <StyledTableCell align="center" >{value.correctSums?value.correctSums:'Nil'}</StyledTableCell>
                                                <StyledTableCell align="center" >{value.TotalMarks?value.TotalMarks:'Nil'}</StyledTableCell>
                                                </StyledTableRow>)
                                            })}
                                                </>
                                            )}
                                    </>
                                ))}

                            </TableBody> :
                            <TableBody>
                                <StyledTableCell> </StyledTableCell>
                                <StyledTableCell align="right">No Records </StyledTableCell>
                                <StyledTableCell align="left">Available</StyledTableCell>
                                <StyledTableCell> </StyledTableCell>
                                <StyledTableCell> </StyledTableCell>

                            </TableBody>
                        }
                    </Table>

                    {/* Detailed Table */}


                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined" size={"small"} color="secondary"
                    endIcon={<CancelOutlinedIcon />}>Close</Button>
            </DialogActions>

        </Dialog>
    );
}