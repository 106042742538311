import React from "react";
import DashboardBg from "./DashboardBg.png";
import DashboardMobileBg from "./DashboardMobileBg.png";
import StarLabel from "./StarLabel.png";
import StarLabelMobile from "./StarLabelMobile.png";
import Medal1 from "./Medal1.png";
import Medal2 from "./Medal2.png";
import Medal3 from "./Medal3.png";
import lockedMedal from "./Medals-01.png";
import lockedMedal2 from "./Medals-02.png";
import lockedmedal3 from "./Medals-03.png";
import { LoggedInUserDetails } from "../LoggedInUserDetails/LoggedInUserDetails";
import { FormControl, TextField } from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";
import {
  makeStyles,
  CssBaseline,
  Grid,
  Paper,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
  Snackbar,
  Button,
  useTheme,
  useMediaQuery,
  Backdrop,
  CircularProgress,
  Tab,
  Box,
  Tooltip,
} from "@material-ui/core";
import clsx from "clsx";
import axios from "axios";
import { useEffect, useContext, useRef } from "react";
import InfoIcon from "@material-ui/icons/Info";
import box from "./box.png";
import boxMobile from "./box-mobile.png";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import GraphAnalysis from "./GraphAnalysis";
import congratulationsImage from "./Congratulations.png";
import { Star } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
  image: {
    height: "112vh",
    backgroundImage: `url(${DashboardBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100vw",
  },
  mobileImage: {
    height: "150vh",
    backgroundImage: `url(${DashboardMobileBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  starCountlogo: {
    marginLeft: "25%",
    marginTop: "-2%",
    height: "25vh",
  },
  mobileStarCountlogo: {
    marginLeft: "-1%",
    marginTop: "1%",
    height: "8vh",
    width:"100%"
  },
  Medallogo: {
    marginLeft: "65%",
    marginTop: "-10%",
    height: "40vh",
  },MedalTwologo:{
    marginLeft: "65%",
    marginTop: "3%",
    height: "40vh",
  },
  MedalSilverlogo: {
    marginLeft: "65%",
    marginTop: "6%",
    height: "40vh",
  },MedalGoldlogo: {
    marginLeft: "65%",
    marginTop: "5%",
    height: "40vh",
  },
  mobileStarMedallogo: {
    marginLeft: "32%",
    marginTop: "7%",
    height: "20vh",
  },
  tooltip: {
    //backgroundColor: '#fcebb9',
    fontSize: "30px",
    fontWeight: "bold",
  },
  tooltipMobile: {
    //backgroundColor: '#fcebb9',
    fontSize: "18px",
    fontWeight: "bold",
  },tooltipSilverMobile: {
    marginLeft: "86%",
    fontSize: "18px",
    fontWeight: "bold",
    marginTop: "-11%",
  },tooltipSilverMobileCong: {
    marginLeft: "76%",
    fontSize: "18px",
    fontWeight: "bold",
    marginTop: "-7%",
  }, tooltipSilverCong: {
    marginLeft: "47%",
    fontSize: "30px",
    fontWeight: "bold",
    marginTop: "-4%",
  },tooltipSilver: {
    marginLeft: "42%",
    fontSize: "30px",
    fontWeight: "bold",
    marginTop: "-4%",
  }, tooltipGold: {
    marginLeft: "42%",
    fontSize: "30px",
    fontWeight: "bold",
    marginTop: "-4%",
  },tooltipGoldMobile: {
    marginLeft: "70%",
    fontSize: "18px",
    fontWeight: "bold",
    marginTop: "-7%",
  },
  boxlogo: {
    marginLeft: "0%",
    marginTop: "-21%",
    height: "50vh",
    width: "400",
  },
  mobileBoxlogo: {
    marginLeft: "-6%",
    marginTop: "-65%",
    height: "20vh",
    width: "450px",
  },tablogo:{
    marginLeft: "-6%",
    marginTop: "-65%",
    height: "20vh",
    width: "100%",
  },
  paperBackground: {
    padding: theme.spacing(30, 0, 0, 0),
    //  backgroundImage: `url(${HeaderImage})`,
    backgroundColor: "#f38020",
  },
  paper: {
    padding: theme.spacing(10, 0, 0, 0),
  },
  star1: {
    marginLeft: "23%",
    marginTop: "3%",
  },
  star2: {
    marginLeft: "1%",
    marginTop: "3%",
  },
  star3: {
    marginLeft: "1%",
    marginTop: "3%",
  },
  star4: {
    marginLeft: "1%",
    marginTop: "3%",
  },
  star5: {
    marginLeft: "1%",
    marginTop: "3%",
  },
  star6: {
    marginLeft: "13%",
    marginTop: "3%",
  },
  star7: {
    marginLeft: "1%",
    marginTop: "3%",
  },
  star8: {
    marginLeft: "1%",
    marginTop: "3%",
  },
  star9: {
    marginLeft: "1%",
    marginTop: "3%",
  },
  star10: {
    marginLeft: "1%",
    marginTop: "3%",
  },
  mobilestar1: {
    marginLeft: "9%",
    marginTop: "1%",
  },
  mobilestar2: {
    marginLeft: "1%",
    marginTop: "3%",
  },
  mobilestar3: {
    marginLeft: "1%",
    marginTop: "3%",
  },
  mobilestar4: {
    marginLeft: "1%",
    marginTop: "3%",
  },
  mobilestar5: {
    marginLeft: "2%",
    marginTop: "3%",
  },
  mobilestar6: {
    marginLeft: "20%",
    marginTop: "3%",
  },
  mobilestar7: {
    marginLeft: "1%",
    marginTop: "3%",
  },
  mobilestar8: {
    marginLeft: "1%",
    marginTop: "3%",
  },
  mobilestar9: {
    marginLeft: "1%",
    marginTop: "3%",
  },
  mobilestar10: {
    marginLeft: "1%",
    marginTop: "3%",
  },
  tabstar6: {
    marginLeft: "40%",
    marginTop: "3%",
  },
  boxText1: {
    marginLeft: "23%",
    marginTop: "-17%",
    fontWeight: "bold",
    fontSize: "16px",
    color: "black",
    fontFamily: "FilsonSoftW03-Bold",
  },
  boxText2: {
    marginLeft: "53%",
    marginTop: "-2%",
    fontWeight: "bold",
    fontSize: "16px",
    color: "black",
    fontFamily: "FilsonSoftW03-Bold",
  },
  mobileBoxText1: {
    marginLeft: "8%",
    marginTop: "-30%",
    fontWeight: "bold",
    fontSize: "9px",
    color: "black",
    fontFamily: "FilsonSoftW03-Bold",
  },
  mobileBoxText2: {
    marginLeft: "60%",
    marginTop: "-4%",
    fontWeight: "bold",
    fontSize: "9px",
    color: "black",
    fontFamily: "FilsonSoftW03-Bold",
  },
  tabBoxText1: {
    marginLeft: "8%",
    marginTop: "-18%",
    fontWeight: "bold",
    fontSize: "10px",
    color: "black",
    fontFamily: "FilsonSoftW03-Bold",
  },
  tabBoxText2: {
    marginLeft: "60%",
    marginTop: "-2%",
    fontWeight: "bold",
    fontSize: "10px",
    color: "black",
    fontFamily: "FilsonSoftW03-Bold",
  },
  text: {
    fontFamily: "FilsonSoftW03-Bold",
    marginLeft: "38%",
    marginTop: "-7%",
    marginRight: "30px",
    fontWeight: "bold",
    fontSize: "22px",
    color: "white",
  },
  mobileText: {
    fontFamily: "FilsonSoftW03-Bold",
    marginLeft: "17%",
    marginTop: "-12%",
    marginRight: "30px",
    fontWeight: "bold",
    fontSize: "15px",
    color: "white",
  },tabStatCountText: {
    fontFamily: "FilsonSoftW03-Bold",
    marginLeft: "17%",
    marginTop: "-7%",
    marginRight: "30px",
    fontWeight: "bold",
    fontSize: "15px",
    color: "white",
  },
  practiceText: {
    fontFamily: "FilsonSoftW03-Bold",
    marginLeft: "30%",
    marginTop: "-10%",
    marginRight: "30px",
    fontWeight: "bold",
    fontSize: "24px",
    color: "black",
    width:"200"
  },
  practiceTextMobile: {
    fontFamily: "FilsonSoftW03-Bold",
    marginLeft: "15%",
    marginTop: "10%",
    marginRight: "30px",
    fontWeight: "bold",
    fontSize: "14px",
    color: "black",
  },
  Congrats: {
    marginLeft: "33%",
    height: "25vh",
    marginTop: "5%",
  },
  mobileCongrats: {
    marginLeft: "20%",
    height: "15vh",
    marginTop: "5%",
  },
  winningTextWeb1: {
    marginLeft: "22%",
    marginTop: "1%",
    fontWeight: "bold",
    fontSize: "16px",
    color: "black",
    fontFamily: "FilsonSoftW03-Bold",
  },
  winningTextMobile: {
    marginLeft: "9%",
    fontWeight: "bold",
    fontSize: "8.5px",
    color: "black",
    fontFamily: "FilsonSoftW03-Bold",
    marginTop: "0%",
  },
  winningTextMobile2: {
    marginLeft: "59%",
    fontWeight: "bold",
    fontSize: "8.5px",
    color: "black",
    fontFamily: "FilsonSoftW03-Bold",
    marginTop: "-4%",
  },
  winningTextWeb2: {
    marginLeft: "52%",
    marginTop: "-2%",
    fontWeight: "bold",
    fontSize: "16px",
    color: "black",
    fontFamily: "FilsonSoftW03-Bold",
  },
  winningTextTab2: {
    marginLeft: "60%",
    fontWeight: "bold",
    fontSize: "9px",
    color: "black",
    fontFamily: "FilsonSoftW03-Bold",
    marginTop: "-2%",
  },
}));

export default function SummaryDashBoard(props) {
  const classes = useStyles();
  let theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const tab = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const [count, setCount] = React.useState(0);
  const { loggedInUserInfo, setLoggedInUserInfo } =
    React.useContext(LoggedInUserDetails);
  const BASE_URL = `${process.env.REACT_APP_API_URL}`;
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const timeout = useRef(null);
  const [bronzeWon, setBronzeWon] = React.useState(false);
  const [silverWon, setSilverWon] = React.useState(false);
  const [goldWon, setGoldWon] = React.useState(false);
  const [lastweekDay1Count, setLastWeekDay1Count] = React.useState(0);
  const [lastweekDay2Count, setLastWeekDay2Count] = React.useState(0);
  const [lastweekDay3Count, setLastWeekDay3Count] = React.useState(0);
  const [lastweekDay4Count, setLastWeekDay4Count] = React.useState(0);
  const [lastweekDay5Count, setLastWeekDay5Count] = React.useState(0);
  const [lastweekDay6Count, setLastWeekDay6Count] = React.useState(0);
  const [lastweekDay7Count, setLastWeekDay7Count] = React.useState(0);
  const [currentDay1Count, setCurrentDay1Count] = React.useState(0);
  const [currentDay2Count, setCurrentDay2Count] = React.useState(0);
  const [currentDay3Count, setCurrentDay3Count] = React.useState(0);
  const [currentDay4Count, setCurrentDay4Count] = React.useState(0);
  const [currentDay5Count, setCurrentDay5Count] = React.useState(0);
  const [currentDay6Count, setCurrentDay6Count] = React.useState(0);
  const [currentDay7Count, setCurrentDay7Count] = React.useState(0);
  const currentstarText3 = "Good Job ! Try for more this week ";
  const currentstarText4 = "Awesome ! Aim for 5 stars ";
  const currentstarText5 = "Great Job ! Keep it up ";
  const thisweekstar0Text = "Practice 3 more days to get 3 stars ";
  const thisweekstar1Text = "Practice 2 more days to get 3 stars";
  const thisweekstar2Text = "Practice 1 more day to get 3 stars ";
  const thisweekstar3Text = "Practice 1 more day to get 4 stars ";
  const thisweekstar4Text = "Practice 1 more day to get 5 stars ";
  const thisweekstar5Text = "Awesome !";
  const [winningText1, setWinningText1] = React.useState("");
  const [winningText2, setWinningText2] = React.useState("");
  const wonBronzeText = "You have won the bronze medal!";
  const practiceMoreText = "Practice more to win this medal";
  const congratsGoldMedalText = "Congrats ! You are a Gold medalist";
  const congratsSilverText = "Congrats ! You are a silver medalist";
  const bronzeInfo = "To win a bronze medal get 3 stars or above for 2 weeks";
  const silverInfo = " To win a silver medal  get 3 stars or above for 3 weeks";
  const goldInfo = "To win a gold medal  get 3 stars or above for 4 weeks";
  const [classList, setClassList] = React.useState([]);
  const refresh =props.refresh;
  const[starCountToFill,setStarCountToFill] =React.useState("");
  const classData = classList.map((entity) => {
    return { classId: entity.classId, className: entity.className };
  });
  useEffect(() => {
    console.log("entering into medal api")
    if(loggedInUserInfo.studentId > 0){
      axios
      .get(
        BASE_URL +
          `/examinationParticipation/studentMedal/get/${loggedInUserInfo.studentId}`,
        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("authorization"),
          },
        }
      )
      .then((response) => {
        setBronzeWon(response.data.responseData.bronzeMedal);
        setSilverWon(response.data.responseData.silverMedal);
        setGoldWon(response.data.responseData.goldMEdal)
      })
    }
  }, [loggedInUserInfo.studentId]);


  const show = () => {
    setOpen(true);
    timeout.current = setTimeout(() => setOpen(false), 6000);
  };
  const show1 = () => {
    setOpen1(true);
    timeout.current = setTimeout(() => setOpen1(false), 6000);
  };
  const show2 = () => {
    setOpen2(true);
    timeout.current = setTimeout(() => setOpen2(false), 6000);
  };
  useEffect(() => {
    if(loggedInUserInfo.studentId > 0){
    axios
      .get(
        BASE_URL +
          `/examinationParticipation/examParticipation/previous/count/${loggedInUserInfo.studentId}`,
        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("authorization"),
          },
        }
      )
      .then((response) => {
        setCount(response.data.responseData.yesterdayStudentCount);
        setLastWeekDay1Count(
          response.data.responseData.lastWeekStudentCount[0].count
        );
        setLastWeekDay2Count(
          response.data.responseData.lastWeekStudentCount[1].count
        );
        setLastWeekDay3Count(
          response.data.responseData.lastWeekStudentCount[2].count
        );
        setLastWeekDay4Count(
          response.data.responseData.lastWeekStudentCount[3].count
        );
        setLastWeekDay5Count(
          response.data.responseData.lastWeekStudentCount[4].count
        );
        setLastWeekDay6Count(
          response.data.responseData.lastWeekStudentCount[5].count
        );
        setLastWeekDay7Count(
          response.data.responseData.lastWeekStudentCount[6].count
        );
        setCurrentDay1Count(
          response.data.responseData.currentWeekStudentCount[0].count
        );
        setCurrentDay2Count(
          response.data.responseData.currentWeekStudentCount[1].count
        );
        setCurrentDay3Count(
          response.data.responseData.currentWeekStudentCount[2].count
        );
        setCurrentDay4Count(
          response.data.responseData.currentWeekStudentCount[3].count
        );
        setCurrentDay5Count(
          response.data.responseData.currentWeekStudentCount[4].count
        );
        setCurrentDay6Count(
          response.data.responseData.currentWeekStudentCount[5].count
        );
        setCurrentDay7Count(
          response.data.responseData.currentWeekStudentCount[6].count
        );
      });
  }}, [refresh,loggedInUserInfo.studentId]);
  useEffect(() => {
    let sumOfStars =
      currentDay1Count +
      currentDay2Count +
      currentDay3Count +
      currentDay4Count +
      currentDay5Count +
      currentDay6Count +
      currentDay7Count;
    if (sumOfStars == 0) {
      setWinningText2(thisweekstar0Text);
      setStarCountToFill("0");
    } else if (sumOfStars == 1) {
      setWinningText2(thisweekstar1Text);
      setStarCountToFill("1");
    } else if (sumOfStars == 2) {
      setWinningText2(thisweekstar2Text);
      setStarCountToFill("2");
    } else if (sumOfStars == 3) {
      setWinningText2(thisweekstar3Text);
      setStarCountToFill("3");
    } else if (sumOfStars == 4) {
      setWinningText2(thisweekstar4Text);
      setStarCountToFill("4");
    } else {
      setWinningText2(thisweekstar5Text);
      setStarCountToFill("5")

    }
  }, [
    currentDay1Count,
    currentDay2Count,
    currentDay3Count,
    currentDay4Count,
    currentDay5Count,
    currentDay6Count,
    currentDay7Count,
  ]);
  useEffect(() => {
    let sumOfStarsLastWeek =
      lastweekDay1Count +
      lastweekDay2Count +
      lastweekDay3Count +
      lastweekDay4Count +
      lastweekDay5Count +
      lastweekDay6Count +
      lastweekDay7Count;
    if (sumOfStarsLastWeek == 3) {
      setWinningText1(currentstarText3);
    } else if (sumOfStarsLastWeek == 4) {
      setWinningText1(currentstarText4);
    } else if (sumOfStarsLastWeek == 5) {
      setWinningText1(currentstarText5);
    } else if (sumOfStarsLastWeek == 6) {
      setWinningText1(currentstarText5);
    } else if (sumOfStarsLastWeek == 7) {
      setWinningText1(currentstarText5);
    } else {
      setWinningText1("No Practice Done Last Week");
    }
  }, [
    lastweekDay1Count,
    lastweekDay2Count,
    lastweekDay3Count,
    lastweekDay4Count,
    lastweekDay5Count,
    lastweekDay6Count,
    lastweekDay7Count,
  ]);

  function star0fill(){
    return (
      <React.Fragment>

{(currentDay1Count+currentDay2Count+currentDay3Count+currentDay4Count+ currentDay5Count+currentDay6Count+currentDay7Count )== 0 ? 
  (
      <><StarOutlineIcon
          fontSize="large"
          className={classes.star6} /><StarOutlineIcon
            fontSize="large"
            className={classes.star7} /><StarOutlineIcon
            fontSize="large"
            className={classes.star8} /><StarOutlineIcon
            fontSize="large"
            className={classes.star9} /><StarOutlineIcon
            fontSize="large"
            className={classes.star10} />
    
    </>
    ) : ""}
        
      </React.Fragment>
    );
  
  }
  function star1fill(){
    return (
      <React.Fragment>

{(currentDay1Count+currentDay2Count+currentDay3Count+currentDay4Count+ currentDay5Count+currentDay6Count+currentDay7Count )== 1 ? (
      <><Star
            fontSize="large"
            className={classes.star6}
            style={{ color: "#f4c430" }} /><StarOutlineIcon
              fontSize="large"
              className={classes.star7} /><StarOutlineIcon
              fontSize="large"
              className={classes.star8} /><StarOutlineIcon
              fontSize="large"
              className={classes.star9} /><StarOutlineIcon
              fontSize="large"
              className={classes.star10} /></>
    ) : (
      <><StarOutlineIcon
          fontSize="large"
          className={classes.star6} /><StarOutlineIcon
            fontSize="large"
            className={classes.star7} /><StarOutlineIcon
            fontSize="large"
            className={classes.star8} /><StarOutlineIcon
            fontSize="large"
            className={classes.star9} /><StarOutlineIcon
            fontSize="large"
            className={classes.star10} />
    
    </>
    )}
        
      </React.Fragment>
    );
  
 
  }
  function star2fill(){
    return (
      <React.Fragment>
    {(currentDay1Count+currentDay2Count +currentDay3Count+currentDay4Count+ currentDay5Count+currentDay6Count+currentDay7Count )== 2 ? (
    
      <><Star
          fontSize="large"
          className={classes.star6}
          style={{ color: "#f4c430" }} /><Star
            fontSize="large"
            className={classes.star7}
            style={{ color: "#f4c430" }} />
            <StarOutlineIcon
        fontSize="large"
        className={classes.star8} /><StarOutlineIcon
        fontSize="large"
        className={classes.star9} /><StarOutlineIcon
        fontSize="large"
        className={classes.star10} /></>
    ) : (
      <><StarOutlineIcon
      fontSize="large"
      className={classes.star6} /><StarOutlineIcon
        fontSize="large"
        className={classes.star7} /><StarOutlineIcon
        fontSize="large"
        className={classes.star8} /><StarOutlineIcon
        fontSize="large"
        className={classes.star9} /><StarOutlineIcon
        fontSize="large"
        className={classes.star10} />

</>
    )}
       </React.Fragment>
    );
  
  }
  function star3fill(){
    return (
      <React.Fragment>
    {(currentDay1Count+currentDay2Count +currentDay3Count+currentDay4Count+ currentDay5Count+currentDay6Count+currentDay7Count) == 3 ? (
    
      <><Star
      fontSize="large"
      className={classes.star6}
      style={{ color: "#f4c430" }} /><Star
        fontSize="large"
        className={classes.star7}
        style={{ color: "#f4c430" }} />
        <Star
        fontSize="large"
        className={classes.star8}
        style={{ color: "#f4c430" }}
      /><StarOutlineIcon
      fontSize="large"
      className={classes.star9} /><StarOutlineIcon
      fontSize="large"
      className={classes.star10} /></>
    ) : (
      <><StarOutlineIcon
          fontSize="large"
          className={classes.star6} /><StarOutlineIcon
            fontSize="large"
            className={classes.star7} /><StarOutlineIcon
            fontSize="large"
            className={classes.star8} /><StarOutlineIcon
            fontSize="large"
            className={classes.star9} /><StarOutlineIcon
            fontSize="large"
            className={classes.star10} />
    
    </>
    )}
    </React.Fragment>
    );
  
  }
  function star4fill(){
    return (
      <React.Fragment>
    {(currentDay1Count+currentDay2Count +currentDay3Count+currentDay4Count + currentDay5Count+currentDay6Count+currentDay7Count) ==4 ? (
    
      <><Star
      fontSize="large"
      className={classes.star6}
      style={{ color: "#f4c430" }} /><Star
        fontSize="large"
        className={classes.star7}
        style={{ color: "#f4c430" }} />
        <Star
        fontSize="large"
        className={classes.star8}
        style={{ color: "#f4c430" }}
      /><Star
      fontSize="large"
      className={classes.star9}
      style={{ color: "#f4c430" }}
    /><StarOutlineIcon
    fontSize="large"
    className={classes.star10} /></>
    ) : (
      <><StarOutlineIcon
          fontSize="large"
          className={classes.star6} /><StarOutlineIcon
            fontSize="large"
            className={classes.star7} /><StarOutlineIcon
            fontSize="large"
            className={classes.star8} /><StarOutlineIcon
            fontSize="large"
            className={classes.star9} /><StarOutlineIcon
            fontSize="large"
            className={classes.star10} />
    
    </>
    )}
     </React.Fragment>
    );
  }
 function star5fill(){
  return (
    <React.Fragment>
  {(currentDay1Count+currentDay2Count +currentDay3Count+currentDay4Count + currentDay5Count +currentDay6Count+currentDay7Count) >= 5 ? (
    
    <><Star
    fontSize="large"
    className={classes.star6}
    style={{ color: "#f4c430" }} /><Star
      fontSize="large"
      className={classes.star7}
      style={{ color: "#f4c430" }} />
      <Star
      fontSize="large"
      className={classes.star8}
      style={{ color: "#f4c430" }}
    /><Star
    fontSize="large"
    className={classes.star9}
    style={{ color: "#f4c430" }}
  /><Star
  fontSize="large"
  className={classes.star10}
  style={{ color: "#f4c430" }}
/></>
  ) : (
    <><StarOutlineIcon
    fontSize="large"
    className={classes.star6} /><StarOutlineIcon
      fontSize="large"
      className={classes.star7} /><StarOutlineIcon
      fontSize="large"
      className={classes.star8} /><StarOutlineIcon
      fontSize="large"
      className={classes.star9} /><StarOutlineIcon
      fontSize="large"
      className={classes.star10} />

</>
  )}
  </React.Fragment>
  );
 }
  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        className={smallScreen ? classes.mobileImage : classes.image}
      >
        <div>
          <img
            src={smallScreen ? StarLabelMobile : StarLabel}
            className={
              smallScreen ? classes.mobileStarCountlogo : classes.starCountlogo
            }
            alt=""
          />
          <Typography
            className={smallScreen && !tab? classes.mobileText : tab? classes.tabStatCountText :classes.text}
          >
            {`${count}`} students practiced yesterday
          </Typography>
        </div>

        <div
          style={{
            display: bronzeWon || silverWon || goldWon ? "block" : "none",
          }}
        >
          <img
            src={bronzeWon ? congratulationsImage : " "}
            className={smallScreen ? classes.mobileCongrats : classes.Congrats}
            alt=""
          />
        </div>
        <div style={{ display: silverWon || goldWon ? "none" : "block" }}>
          <img
            src={bronzeWon ? Medal3 : lockedmedal3}
            className={
              smallScreen ? classes.mobileStarMedallogo : bronzeWon ? classes.Medallogo : classes.MedalTwologo
            }
            alt=""
          />
          <Typography
            className={
              smallScreen ? classes.practiceTextMobile : classes.practiceText
            }
          >
            {smallScreen
              ? bronzeWon
                ? wonBronzeText
                : practiceMoreText
              : bronzeWon
              ? wonBronzeText
              : practiceMoreText}

            <Tooltip
              disableHoverListener
              onMouseEnter={() => show()}
              onClick={() => show()}
              open={open || false}
              className={smallScreen ? classes.tooltipMobile : classes.tooltip}
              title={bronzeWon ? silverInfo : bronzeInfo}
            >
              <InfoIcon fontSize="small" color="primary" />
            </Tooltip>
          </Typography>
        </div>

        <div style={{ display: !bronzeWon || goldWon ? "none" : "block" }}>
          <img
            src={silverWon ? Medal2 : lockedMedal2}
            className={
              smallScreen ? classes.mobileStarMedallogo : bronzeWon & !silverWon? classes.MedalSilverlogo : classes.Medallogo
            }
            alt=""
          />
          <Typography
            className={
              smallScreen ? classes.practiceTextMobile : classes.practiceText
            }
          >
            {smallScreen
              ? silverWon
                ? congratsSilverText
                : "Practice more to"
              : silverWon
              ? congratsSilverText
              : practiceMoreText}

            {smallScreen ? (!silverWon ? " win this medal" : "") : ""}
            <Tooltip
                style={{ display: bronzeWon ? "block" : "none" }}
                disableHoverListener
                onMouseEnter={() => show1()}
                onClick={() => show1()}
                open={open1 || false}
                className={
                  smallScreen ? silverWon ? classes.tooltipSilverMobileCong :classes.tooltipSilverMobile : silverWon ? classes.tooltipSilverCong:classes.tooltipSilver
                }
                title={silverWon ? goldInfo : silverInfo}
              >
                <InfoIcon fontSize="medium" color="primary" />
              </Tooltip>
          </Typography>
        </div>
        <div style={{ display: silverWon ? "block" : "none" }}>
          <img
            src={goldWon ? Medal1 : lockedMedal}
            className={
              smallScreen ? classes.mobileStarMedallogo : goldWon ? classes.Medallogo :classes.MedalTwologo
            }
            alt=""
          />
          <Typography
            className={
              smallScreen ? classes.practiceTextMobile : classes.practiceText
            }
          >
            {smallScreen
              ? goldWon
                ? congratsGoldMedalText
                : practiceMoreText
              : goldWon
              ? congratsGoldMedalText
              : practiceMoreText}
            <Tooltip
              style={{ display: goldWon ? "none" : "block" }}
              disableHoverListener
              onMouseEnter={() => show2()}
              onClick={() => show2()}
              open={open2 || false}
              className={smallScreen ? classes.tooltipGoldMobile : classes.tooltipGold}
              title={goldInfo}
            >
              <InfoIcon fontSize="medium" color="primary" />
            </Tooltip>
          </Typography>
        </div>
        <div>
          <Grid item className={classes.paper} xs={12} md={12}>
            <Paper className={classes.paperBackground}>
              {
                <>
                  <img
                    src={smallScreen? boxMobile : box}
                    className={
                      smallScreen && !tab ? classes.mobileBoxlogo : tab ?  classes.tablogo :classes.boxlogo
                    }
                    alt=""
                  />
                  <Typography
                    className={
                      smallScreen && !tab
                        ? classes.mobileBoxText1
                        : tab
                        ? classes.tabBoxText1
                        : classes.boxText1
                    }
                  >
                    Last Week ( Monday - Sunday)
                  </Typography>
                  <Typography
                    className={
                      smallScreen && !tab
                        ? classes.mobileBoxText2
                        : tab
                        ? classes.tabBoxText2
                        : classes.boxText2
                    }
                  >
                    This Week ( Monday - Sunday)
                  </Typography>

                  <div>
                    {smallScreen ? (
                      <>
                        {lastweekDay1Count +
                          lastweekDay2Count +
                          lastweekDay3Count +
                          lastweekDay4Count +
                          lastweekDay5Count +
                          lastweekDay6Count +
                          lastweekDay7Count>=
                        3 ? (
                          <Star
                            fontSize="small"
                            className={classes.mobilestar1}
                            style={{ color: "#f4c430" }}
                          />
                        ) : (
                          <StarOutlineIcon
                            fontSize="small"
                            className={classes.mobilestar1}
                          />
                        )}
                        {lastweekDay1Count +
                          lastweekDay2Count +
                          lastweekDay3Count +
                          lastweekDay4Count +
                          lastweekDay5Count+
                          lastweekDay6Count +
                          lastweekDay7Count >=
                        3 ? (
                          <Star
                            fontSize="small"
                            className={classes.mobilestar2}
                            style={{ color: "#f4c430" }}
                          />
                        ) : (
                          <StarOutlineIcon
                            fontSize="small"
                            className={classes.mobilestar2}
                          />
                        )}
                        {lastweekDay1Count +
                          lastweekDay2Count +
                          lastweekDay3Count +
                          lastweekDay4Count +
                          lastweekDay5Count +
                          lastweekDay6Count +
                          lastweekDay7Count >=
                        3 ? (
                          <Star
                            fontSize="small"
                            className={classes.mobilestar3}
                            style={{ color: "#f4c430" }}
                          />
                        ) : (
                          <StarOutlineIcon
                            fontSize="small"
                            className={classes.mobilestar3}
                          />
                        )}
                        {lastweekDay1Count +
                          lastweekDay2Count +
                          lastweekDay3Count +
                          lastweekDay4Count +
                          lastweekDay5Count +
                          lastweekDay6Count +
                          lastweekDay7Count >=
                        4 ? (
                          <Star
                            fontSize="small"
                            className={classes.mobilestar4}
                            style={{ color: "#f4c430" }}
                          />
                        ) : (
                          <StarOutlineIcon
                            fontSize="small"
                            className={classes.mobilestar4}
                          />
                        )}
                        {lastweekDay1Count +
                          lastweekDay2Count +
                          lastweekDay3Count +
                          lastweekDay4Count +
                          lastweekDay5Count +
                          lastweekDay6Count +
                          lastweekDay7Count >=
                        5 ? (
                          <Star
                            fontSize="small"
                            className={classes.mobilestar5}
                            style={{ color: "#f4c430" }}
                          />
                        ) : (
                          <StarOutlineIcon
                            fontSize="small"
                            className={classes.mobilestar5}
                          />
                        )}
                        {currentDay1Count + currentDay2Count + currentDay3Count+ currentDay4Count+currentDay5Count+currentDay6Count+currentDay7Count >= 1 ? (
                          <Star
                            fontSize="small"
                            className={
                              tab ? classes.tabstar6 : classes.mobilestar6
                            }
                            style={{ color: "#f4c430" }}
                          />
                        ) : (
                          <StarOutlineIcon
                            fontSize="small"
                            className={
                              tab ? classes.tabstar6 : classes.mobilestar6
                            }
                          />
                        )}
                        {currentDay2Count+ currentDay3Count+ currentDay4Count+currentDay5Count+currentDay6Count+currentDay7Count >= 1 ? (
                          <Star
                            fontSize="small"
                            className={classes.mobilestar7}
                            style={{ color: "#f4c430" }}
                          />
                        ) : (
                          <StarOutlineIcon
                            fontSize="small"
                            className={classes.mobilestar7}
                          />
                        )}
                        {currentDay3Count+ currentDay4Count+currentDay5Count+currentDay6Count+currentDay7Count >= 1 ? (
                          <Star
                            fontSize="small"
                            className={classes.mobilestar8}
                            style={{ color: "#f4c430" }}
                          />
                        ) : (
                          <StarOutlineIcon
                            fontSize="small"
                            className={classes.mobilestar8}
                          />
                        )}
                        {currentDay4Count+currentDay5Count+currentDay6Count+currentDay7Count ? (
                          <Star
                            fontSize="small"
                            className={classes.mobilestar9}
                            style={{ color: "#f4c430" }}
                          />
                        ) : (
                          <StarOutlineIcon
                            fontSize="small"
                            className={classes.mobilestar9}
                          />
                        )}

                        {currentDay5Count+currentDay6Count+currentDay7Count >=1 ? (
                          <Star
                            fontSize="small"
                            className={classes.mobilestar10}
                            style={{ color: "#f4c430" }}
                          />
                        ) : (
                          <StarOutlineIcon
                            fontSize="small"
                            className={classes.mobilestar10}
                          />
                        )}
                      </>
                    ) : (
                      <><><>
                          {lastweekDay1Count +
                            lastweekDay2Count +
                            lastweekDay3Count +
                            lastweekDay4Count +
                            lastweekDay5Count +
                            lastweekDay6Count +
                            lastweekDay7Count >=
                            3 ? (
                            <Star
                              fontSize="large"
                              className={classes.star1}
                              style={{ color: "#f4c430" }} />
                          ) : (
                            <StarOutlineIcon
                              fontSize="large"
                              className={classes.star1}
                              color="white" />
                          )}
                          {lastweekDay1Count +
                            lastweekDay2Count +
                            lastweekDay3Count +
                            lastweekDay4Count +
                            lastweekDay5Count +
                            lastweekDay6Count +
                            lastweekDay7Count >=
                            3 ? (
                            <Star
                              fontSize="large"
                              className={classes.star2}
                              style={{ color: "#f4c430" }} />
                          ) : (
                            <StarOutlineIcon
                              fontSize="large"
                              className={classes.star2} />
                          )}
                          {lastweekDay1Count +
                            lastweekDay2Count +
                            lastweekDay3Count +
                            lastweekDay4Count +
                            lastweekDay5Count +
                            lastweekDay6Count +
                            lastweekDay7Count >=
                            3 ? (
                            <Star
                              fontSize="large"
                              className={classes.star3}
                              style={{ color: "#f4c430" }} />
                          ) : (
                            <StarOutlineIcon
                              fontSize="large"
                              className={classes.star3} />
                          )}
                          {lastweekDay1Count +
                            lastweekDay2Count +
                            lastweekDay3Count +
                            lastweekDay4Count +
                            lastweekDay5Count +
                            lastweekDay6Count +
                            lastweekDay7Count >=
                            4 ? (
                            <Star
                              fontSize="large"
                              className={classes.star4}
                              style={{ color: "#f4c430" }} />
                          ) : (
                            <StarOutlineIcon
                              fontSize="large"
                              className={classes.star4} />
                          )}
                          {lastweekDay1Count +
                            lastweekDay2Count +
                            lastweekDay3Count +
                            lastweekDay4Count +
                            lastweekDay5Count +
                            lastweekDay6Count +
                            lastweekDay7Count >=
                            5 ? (
                            <Star
                              fontSize="large"
                              className={classes.star5}
                              style={{ color: "#f4c430" }} />
                          ) : (
                            <StarOutlineIcon
                              fontSize="large"
                              className={classes.star5} />
                          )}
                        </>{starCountToFill == "1" ? star1fill() : ""}</>
                        <>{starCountToFill == "2" ? star2fill() : ""}</>
                        <>{starCountToFill == "3" ? star3fill() : ""}</>
                        <>{starCountToFill == "4" ? star4fill() : ""}</>
                        <>{starCountToFill == "5" ? star5fill() : ""}</>
                        <>{starCountToFill == "0" ? star0fill() : ""}</>
                        </>

                    )}
                  </div>
                  <Typography
                    className={
                      smallScreen
                        ? classes.winningTextMobile
                        : classes.winningTextWeb1
                    }
                  >
                    {`${winningText1}`}
                  </Typography>
                  <Typography
                    className={
                      smallScreen && !tab
                        ? classes.winningTextMobile2
                        : tab
                        ? classes.winningTextTab2
                        : classes.winningTextWeb2
                    }
                  >
                    {`${winningText2}`}
                  </Typography>
                </>
              }
            </Paper>
          </Grid>
        </div>
     
          <GraphAnalysis refresh ={refresh}/>
      </Grid>
    </>
  );
}
