import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Dialog, DialogContent, DialogActions, DialogTitle, Button, Snackbar, CircularProgress } from '@material-ui/core';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import axios from 'axios';
import dayjs from 'dayjs';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles({
    table: {
        minWidth: 'auto',
    },
    dialogBoxHeight: {
        '& .MuiDialog-paperFullWidth': {
            height: 'auto',
        }
    }
});

export default function Promotion(props) {
    const { onClose, open, selectedStudentFromTable, nextExamGroupId, examinationId } = props;
    const classes = useStyles();
    const handleClose = () => {
        onClose();
    }
    const [isButtonDisabled, setIsButtonDisabled] = React.useState(false);
    const BASE_URL = `${process.env.REACT_APP_API_URL}`;
    const [state, setState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const { vertical, horizontal, openAlert, message, severity } = state;
    const handleAlertClose = () => {
        setState({ ...state, openAlert: false });
    };

    const handleEntry=()=>{
        setIsButtonDisabled(false)
    }

    const onSubmit = async () => {
        setIsButtonDisabled(true);

        for (let studentObject = 0; studentObject < selectedStudentFromTable.length; studentObject++) {
            selectedStudentFromTable[studentObject].schoolNameString = selectedStudentFromTable[studentObject].schoolName;
            selectedStudentFromTable[studentObject].schoolName=null;
           if( selectedStudentFromTable[studentObject].workshop!== null)
            selectedStudentFromTable[studentObject].workshop.studentRegistration = null;
        if(examinationId === 1){
            selectedStudentFromTable[studentObject].studentAccount = null;
            selectedStudentFromTable[studentObject].exam.classNumber = null;
            selectedStudentFromTable[studentObject].batch.exam = null
            selectedStudentFromTable[studentObject].createdDt = null;
            selectedStudentFromTable[studentObject].lastUpdatedDt = null;
            
            selectedStudentFromTable[studentObject].workshop = null;
        
    }
}
        axios.post(BASE_URL + `/students/promote/${nextExamGroupId}`, selectedStudentFromTable,
            {
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem("sip-authorization")
                }
            }).then((response) => {
                setIsButtonDisabled(false);
                onClose(true);
            })
            .catch(function (error) {
                setIsButtonDisabled(false);
                setState({ ...state, openAlert: true, message: 'Error occurred while promoting the students!', severity: "error" });
            });
    }

    const [maxWidth] = React.useState('md');

    return (

        <Dialog fullWidth className={classes.dialogBoxHeight} maxWidth={maxWidth} open={open}
            onClose={handleClose} aria-labelledby="form-dialog-title" onEntering={handleEntry}>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleAlertClose}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert onClose={handleAlertClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <DialogTitle id="form-dialog-title">Students</DialogTitle>
            <DialogContent style={{ padding: '0 5%' }} dividers='paper' >
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Child Name</TableCell>
                                <TableCell >Class</TableCell>
                                <TableCell >School</TableCell>
                                <TableCell >Total Marks</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {selectedStudentFromTable.map((student) => (
                                <TableRow key={student.studentId}>
                                    <TableCell component="th" scope="student">
                                        {student.childName}
                                    </TableCell>
                                    <TableCell >{student.classNumber && student.classNumber.name}</TableCell>
                                    <TableCell >{student.schoolName===null?student.schoolNameString:student.schoolName}</TableCell>
                                    <TableCell >{student.totalMarks}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>

                <Button onClick={handleClose} variant="outlined" size={"small"} color="secondary"
                    endIcon={<CancelOutlinedIcon />}>
                    Cancel
                        </Button>
                <Button disabled={isButtonDisabled} variant="outlined" size={"small"} color="primary"
                    onClick={onSubmit} endIcon={<SaveOutlinedIcon />} >
                    Submit
                        </Button>
                        {isButtonDisabled && <CircularProgress size={24} />}


            </DialogActions>

        </Dialog>
    );
}