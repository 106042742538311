import React, { useEffect } from "react";
import {
  Grid,
  makeStyles,
  CssBaseline,
  Box,
  Button,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import StudentLoginBackground from "./student-login.svg";
import Copyright from "../../Common/Copyright";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import jwt_decode from "jwt-decode";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import logo from "../../Examination/contestLanding/images/sip_abacus_logo.png";
import sipArithmeticLogo from "../../Examination/contestLanding/images/logo.jpg";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import prodigyLogo from "./prodigyLogo.jpg";
import sipAGClogo2022 from "../../Examination/contestLanding/images/SIP_AGC_Logo_2022.png";
import landingPage from "../../Students/Login/Landing_page.jpg";
import mobileBackgroundLanding from "../../Students/Login/landing_page_mobile.png";
//import createActivityDetector from "activity-detector";

StudentLogin.propTypes = {};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& .MuiFormLabel-root": {
      fontWeight: "bold" // or black
    }
  },
  image: {
    height: "100vh",
    backgroundImage: `url(${landingPage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  mobileImage: {
    height: "100vh",
    backgroundImage: `url(${mobileBackgroundLanding})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(12, 6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      //alignment with respect to image on laptops
      paddingLeft: theme.spacing(10),
    },
    [theme.breakpoints.down("sm")]: {
      //alignment with respect to image on laptops
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      opacity: 0.95,
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    // marginTop: theme.spacing(),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(100),
      paddingRight: theme.spacing(3),
    },
  },
  submit: {
    margin: theme.spacing(3, 15, 2),
    background:
      "#F2921D" /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */,
    opacity: 1,
    position: "relative",
    color: "white",
  },
  text: {
    fontSize: "42px",
    fontWeight: "bold",
    // color: "#43413e",
   fontFamily: "FilsonSoftW03-Heavy",
   //className:"font-face-filsonSoftPro",
    margin: theme.spacing(5, -9),
  },
  logo: {
    marginLeft: "69%",
    marginTop: "-30%",
  },
  mobileLogo: {
    marginLeft: "18%",
    marginTop: "-35%",
  },
  mobilePaper: {
    margin: theme.spacing(14, 6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      //alignment with respect to image on laptops
      paddingLeft: theme.spacing(10),
    },
  },
  mobileSubmit: {
    margin: theme.spacing(3, 10, 2),
    background:
      "#F2921D" /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */,
    opacity: 1,
    position: "relative",
    color: "white",
  },
  mobileText: {
    fontSize: "17px",
    fontWeight: "bold",
    // color: "#43413e",
    fontFamily: "FilsonSoftW03-Heavy",
   // className:"font-face-filsonSoftHeavy",
    margin: theme.spacing(5, 0, 0, 1),
  },
}));
//function useIdle(options) {
// const [isIdle, setIsIdle] = React.useState(false);
// React.useEffect(() => {
// const activityDetector = createActivityDetector(options);
//  activityDetector.on("idle", () => setIsIdle(true));
// activityDetector.on("active", () => setIsIdle(false));
// return () => activityDetector.stop();
// }, []);
// return isIdle;
//}
function StudentLogin(props) {
  const classes = useStyles();
  const history = useHistory();
  const { register, handleSubmit, errors, getValues } = useForm();
  const BASE_URL = `${process.env.REACT_APP_API_URL}`;
  const [examName, setExamName] = React.useState(props.examination);
  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    message: "",
    severity: "",
  });
  let theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });
  // const isIdle = useIdle({ timeToIdle: 1000 });
  // const [timerLoop, setTimerLoop] = React.useState(true);
  //const [requestStartedAt, setRequestStartedAt] = React.useState();
  //let [timeLeft, setTimeLeft] = React.useState({
  ////   hours: 0,
  //  minutes: 0,
  //   seconds: 0,
  //});
  //const [allotedTime] = React.useState(() => {
  //  const duration = "01:00:00";
  //  const durationInTime = new Date();
  //  const totalHrs = durationInTime.getHours() + parseInt(duration[0]);
  // const totalMins = durationInTime.getMinutes() + parseInt(duration[1]);
  // durationInTime.setHours(totalHrs, totalMins);
  // return durationInTime;
  //});
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const { vertical, horizontal, open, message, severity } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };
  const query = new URLSearchParams(props.location.search);
  let loginId = "";
  let password = "";
    console.log(query);
  if(query != null && query.get("loginId") != null ){
    console.log(query);
     loginId = query.get("loginId");
     password = query.get("password");

  }

  const onSubmit = (data) => {
    data["userType"] = "STUDENT";
    data["loginId"] = data.username;
    axios
      .post(BASE_URL + "/iam/signin", data)
      .then((response) => {
        setState({
          ...state,
          open: true,
          message: "Login successful, redirecting to Dashboard!",
          severity: "success",
        });
        localStorage.setItem("authorization", response.data.access_token);
        localStorage.setItem("refreshToken", response.data.refresh_token);
        var decodedJwt = jwt_decode(response.data.access_token);
        //    setRequestStartedAt(new Date().getTime());
        //  axios.get(BASE_URL + "/master/studentReg/info/referenceId/"+decodedJwt.sub).then((response)=>{

        // });
        if (props.examination === "practice") {
          history.push("/student/portal/dashboard", {
            studentID: decodedJwt.uid,
            studentName: response.data.username,
            studentLoginId: decodedJwt.sub,
            examination: props.examination,
            examCompleted: decodedJwt.examCompleted,
            noOfAttempts: decodedJwt.noOfAttempts,
          });
        } else {
          setState({
            ...state,
            open: true,
            message: "Login failed, Invalid Login ID or Password!",
            severity: "error",
          });
        }
      })
      .catch((error) => {
        setState({
          ...state,
          open: true,
          message: "Login failed, Invalid Login ID or Password!",
          severity: "error",
        });
        // history.push("/student/portal/invalid");
      });
  };
   React.useEffect(() => {
if(loginId != null && loginId != undefined && loginId !=""){
   const data= {
    userType:"STUDENT",
    loginId:loginId,
    password:password
   }
    axios
      .post(BASE_URL + "/iam/signin", data)
      .then((response) => {
        setState({
          ...state,
          open: true,
          message: "Login successful, redirecting to Dashboard!",
          severity: "success",
        });
        localStorage.setItem("authorization", response.data.access_token);
        localStorage.setItem("refreshToken", response.data.refresh_token);
        var decodedJwt = jwt_decode(response.data.access_token);
        //    setRequestStartedAt(new Date().getTime());
        //  axios.get(BASE_URL + "/master/studentReg/info/referenceId/"+decodedJwt.sub).then((response)=>{

        // });
        if (props.examination === "practice") {
          history.push("/student/portal/dashboard", {
            studentID: decodedJwt.uid,
            studentName: response.data.username,
            studentLoginId: decodedJwt.sub,
            examination: props.examination,
            examCompleted: decodedJwt.examCompleted,
            noOfAttempts: decodedJwt.noOfAttempts,
          });
        } else {
          setState({
            ...state,
            open: true,
            message: "Login failed, Invalid Login ID or Password!",
            severity: "error",
          });
        }
      })
      .catch((error) => {
        setState({
          ...state,
          open: true,
          message: "Login failed, Invalid Login ID or Password!",
          severity: "error",
        });
        // history.push("/student/portal/invalid");
      });
    }
  },[loginId]);

  const toInputUppercase = (e) => {
    e.target.value = ("" + e.target.value).toUpperCase();
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  //function calculateDiff(time, difference) {
  //  let differences = difference + (difference % 60);

  //  if (allotedTime !== undefined && allotedTime !== null) {
  //   if (differences > 0) {
  //   time = {
  //      hours: Math.floor((differences / (1000 * 60 * 60)) % 24),
  //      minutes: Math.floor((differences / 1000 / 60) % 60),
  //      seconds: Math.round((differences / 1000) % 60),
  //  === 0 ? Math.ceil(((difference / 1000) % 60)) + .1 :Math.ceil(((difference / 1000) % 60)),
  //    };
  // } else {
  //    time = {
  //      hours: 0,
  //      minutes: 0,
  //     seconds: 0,
  //   };
  //}

  //  if (time.hours === 0 && time.minutes === 0 && time.seconds === 0) {
  //setStopTimer(true);
  //  }
  //  setTimerLoop(false);
  //  return time;
  // }
  //};
  // React.useLayoutEffect(() => {
  //  let durationInMs = new Date().getTime() - requestStartedAt;
  //  let difference = +allotedTime - +new Date();
  //  setTimeLeft(calculateDiff(timeLeft, difference));
  // if (timeLeft.hours == 0 && timeLeft.minutes == 0 && timeLeft.seconds == 0 && isIdle) {
  //  axios.post(BASE_URL + "/iam/refresh-token",
  //   {
  //      headers: {
  //        authorization:
  //          "Bearer " + localStorage.getItem("refreshToken"),
  //      },
  //    }).then((response) => {
  //   localStorage.setItem("authorization", response.data.access_token);
  //   localStorage.setItem("refreshToken", response.data.refresh_token);
  // });
  // }
  // console.log("Entering into calculation ")
  //},[requestStartedAt]);
  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        key={vertical + horizontal}
        autoHideDuration={6000}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
      {(query.get("loginId")!= null && query.get("loginId")!= undefined)? (
        " "
      ) : (
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          className={smallScreen ? classes.mobileImage : classes.image}
        >
          <div className={smallScreen ? classes.mobilePaper : classes.paper}>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
              <img
                src={logo}
                className={smallScreen ? classes.mobileLogo : classes.logo}
              />
              <Typography
                component="h1"
                variant="h5"
                className={smallScreen ? classes.mobileText : classes.text}
              >
                Practice makes perfect
              </Typography>
              <TextField
                variant="outlined"
                margin="normal"
                required
                id="username"
                label="User Name"
                name="username"
                autoComplete="username"
                autoFocus
                inputRef={register({ required: true, minLength: 1 })}
                error={errors.username ? true : false}
                // onInput={toInputUppercase}
                fullWidth
                className={classes.root}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                name="password"
                label="Password"
                id="password"
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChange("password")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {values.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                autoComplete="password"
                inputRef={register({ required: true, minLength: 3 })}
                error={errors.password ? true : false}
                // onInput={toInputUppercase}
                fullWidth
                className={classes.root}
              />
              <Button
                type="submit"
                variant="contained"
                className={smallScreen ? classes.mobileSubmit : classes.submit}
                style={{
                  borderRadius: 15,
                  width: "40%",
                }}
              >
                Sign In
              </Button>
            </form>
          </div>
        </Grid>
      ) }
    </Grid>
  );
}

export default StudentLogin;
