import React from 'react';
import Button from '@material-ui/core/Button';
//import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { TextField, Grid, makeStyles, FormControl, Checkbox, CssBaseline, FormHelperText, InputAdornment, IconButton } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useForm, Controller } from 'react-hook-form';
import { useEffect, useState, Fragment } from 'react';
import _ from "lodash/fp";
import axios from 'axios';
import jwt_decode from "jwt-decode";
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
// import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import EventIcon from '@material-ui/icons/Event';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,

    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        display: "inline-block",

    },
    error: {
        color: "red",
        fontSize: "11px"
    },

    formControl: {
        minWidth: '100%',
        textAlign: 'left',
        marginTop: '5%',
        '& .MuiFormHelperText-contained': {
            margin: 0,

        }
    },
    checkboxPPT: {
        marginTop: '5%',
        marginRight: '10%',
    },
    checkboxSMS: {
        marginTop: '5%',
    },

    invalid: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'red',
                borderWidth: 2,
            },
        },
        marginTop: '5%',
        minWidth: '100%',
        textAlign: 'left',
        '& .MuiFormHelperText-contained': {
            margin: 0,
            display: 'none'

        }

    },
    gridItem: {
        paddingBottom: theme.spacing(4),
        // width: '261px',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },

}));

function FormDialog(props) {
    const classes = useStyles();
    const { onClose, open, selectedStudentFromTable, currentDateAndTimeFromServer } = props;

    const handleClose = () => {
        onClose(false);
    }

    const { control, handleSubmit, register, errors, getValues } = useForm(
        {
            mode: 'all',
        }
    );
    const [testDate, setTestDate] = useState(new Date());
    const [branchId] = useState(JSON.parse(localStorage.getItem("sip-user")).branch.branchId);
    const [userName] = useState(JSON.parse(localStorage.getItem("sip-user")).userName);
    const currentDate = new Date(currentDateAndTimeFromServer);
    const currentHours = currentDate.getHours();
    const currentHoursInStringFormatStartTime = ((currentHours).toString().length === 1 ? ("0" + (currentHours)) : (currentHours).toString());
    const currentMinutes = currentDate.getMinutes().toString().length === 1 ? ("0" + currentDate.getMinutes().toString()) : (currentDate.getMinutes().toString());
    const defaultStartTime = currentHoursInStringFormatStartTime + ":" + currentMinutes;

    const currentMonth = (currentDate.getMonth() + 1).toString().length === 1 ? ("0" + (currentDate.getMonth() + 1).toString()) : (currentDate.getMonth() + 1).toString();
    const defaultValueForStartDate = `${currentDate.getDate()}-${currentMonth}-${currentDate.getFullYear()}`;
    const [submit, setSubmit] = React.useState(false);

    const [workShop, setWorkShop] = React.useState({
        workShopName: ` `,
        workShopDate: currentDate,
        workShopTime: defaultStartTime,
        // agcpptAttended: false,
        sendSMS: false,
        studentRegistration: selectedStudentFromTable,
        organization: {
            organizationId: 1,
        },
        branch: {
            branchId: branchId,
        },
        lastUpdatedBy: userName,
        createdBy: userName
    });

    const autogeneratedWorkshopName = `Workshop_${JSON.parse(localStorage.getItem("sip-user")).branch.code}_${defaultValueForStartDate}`


    // const agcpptAttendedChange = (event) => {
    //     setWorkShop({ ...workShop, ['agcpptAttended']: getValues('agcpptAttended') });
    // };

    const SMSChange = (event) => {
        setWorkShop({ ...workShop, ['sendSMS']: getValues('sendSMS') });
    };

    const BASE_URL = `${process.env.REACT_APP_API_URL}`;
    //    const [studentDetails,setStudentDetails] = React.useState([]);

    const onSubmit = data => {

        for (let studentCount = 0; studentCount < selectedStudentFromTable.length; studentCount++) {
            selectedStudentFromTable[studentCount].studentAccount = null
            selectedStudentFromTable[studentCount].createdDt = null;
            selectedStudentFromTable[studentCount].lastUpdatedDt = null;
            selectedStudentFromTable[studentCount].schoolName=null;
            selectedStudentFromTable[studentCount].schoolNameVerified=null;
        }

        data["createdBy"] = userName;
        data["lastUpdatedBy"] = userName;
        data["active"] = true;
        data["branch"] = { 'branchId': branchId };
        // data["agcpptAttended"] = workShop.agcpptAttended;
        // data["sendSMS"] = workShop.sendSMS;
        data["studentRegistration"] = selectedStudentFromTable;
        data["workShopDate"] = data.workShopDate + " " + data.workShopTime;

        axios.post(BASE_URL + "/workshops", data,
            {
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem("sip-authorization")
                }
            })
            .then(function (response) {
                onClose(true);
            })
            .catch(function (error) {
            });



    }


    useEffect(() => {

        if ((getValues('workShopName') && !errors.workShopName) && (getValues('workShopTime') && !errors.workShopTime)
            && (getValues('workShopDate') && !errors.workShopDate)) {
            setSubmit(true)
        } else {
            setSubmit(false)
        }

        setWorkShop({ ...workShop, ['studentRegistration']: selectedStudentFromTable });

    }, [autogeneratedWorkshopName, getValues('workShopName'), getValues('workShopDate'), getValues('workShopTime'), errors.workShopDate,
        errors.workShopTime, errors.workShopName, testDate, submit]);

    return (
        <div>

            <Grid container justify="center" alignItems="stretch" height="100%">
                <Dialog fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Schedule Workshop</DialogTitle>
                    <DialogContent style={{ padding: '0 5%' }} dividers='paper' >
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <FormControl fullWidth >
                                <TextField
                                    className={(errors.workShopName && classes.invalid) || classes.formControl}
                                    autoFocus
                                    name='workShopName'
                                    defaultValue={autogeneratedWorkshopName}
                                    margin="dense"
                                    variant='outlined'
                                    id="workShopName"
                                    onChange={(() => {
                                        setWorkShop({ ...workShop, ['workShopName']: getValues('workShopName') });
                                    })}
                                    label="Name *"
                                    fullWidth
                                    type="name"
                                    helperText={'Please enter workshop name'}
                                    inputRef={register({
                                        required: true,
                                        maxLength: 100,
                                    })}
                                />
                                {_.get("workShopName.type", errors) === "required" && (
                                    <FormHelperText className={classes.error}>Workshop name is required</FormHelperText>
                                )}
                                {_.get("workShopName.type", errors) === "maxLength" && (
                                    <FormHelperText className={classes.error}>Workshop name cannot exceed 100 characters</FormHelperText>
                                )}
                            </FormControl>

                            <Grid container spacing={2} style={{ marginTop: "0.5%" }}>
                                <Grid item item xs={12} md={6} lg={6} >

                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <FormControl fullWidth>
                                            <KeyboardDatePicker
                                                className={(errors.workShopDate && classes.invalid) || classes.formControl}
                                                size="small"
                                                id="start-date"
                                                minDate={new Date(currentDateAndTimeFromServer)}
                                                minDateMessage={`Date cannot be less than today.`}
                                                label={"Workshop Date*"}

                                                format="dd-MM-yyyy"
                                                inputVariant="outlined"
                                                inputRef={register({
                                                    required: true,
                                                    minLength: 10,
                                                    maxLength: 10,
                                                })}
                                                value={testDate}
                                                onChange={((date) => {
                                                    if (date === null || date === undefined) {
                                                        setTestDate(new Date(currentDateAndTimeFromServer));
                                                    } else {
                                                        setTestDate(date);
                                                    }
                                                })}
                                                helperText='Please schedule the date'
                                                InputLabelProps={{ shrink: true }}
                                                name="workShopDate"
                                                defaultValue={defaultValueForStartDate}
                                            />
                                            <div className={classes.error}>
                                                {_.get("workShopDate.type", errors) === "required" && (
                                                    <FormHelperText className={classes.error}>Workshop date is required</FormHelperText>
                                                )}
                                                {_.get("workShopDate.type", errors) === "minLength" && (
                                                    <FormHelperText className={classes.error}>Workshop date is required</FormHelperText>
                                                )}
                                                {_.get("workShopDate.type", errors) === "maxLength" && (
                                                    <FormHelperText className={classes.error}>Workshop date is required</FormHelperText>
                                                )}
                                            </div>
                                        </FormControl>
                                    </MuiPickersUtilsProvider>


                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <FormControl fullWidth >
                                        <TextField
                                            className={(errors.workShopTime && classes.invalid) || classes.formControl}
                                            id="time"
                                            name='workShopTime'
                                            label="Start Time*"
                                            fullWidth
                                            size="small"
                                            variant='outlined'
                                            helperText='Please schedule the time'
                                            type="time"
                                            defaultValue={defaultStartTime}
                                            inputRef={register({
                                                required: true,
                                            })}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        {_.get("workShopTime.type", errors) === "required" && (
                                            <FormHelperText className={classes.error}>Workshop time is required</FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>

                                {/* <FormControlLabel className={classes.checkboxPPT}

                                    control={<Checkbox checked={workShop.agcpptAttended} name="agcpptAttended"

                                        color="primary" />}

                                    inputRef={register({
                                        required: false,
                                    })}
                                    onClick={agcpptAttendedChange}
                                    label="AGC PPT Attended"
                                /> */}
                                {/* <FormControlLabel className={classes.checkboxSMS}

                                    control={<Checkbox checked={workShop.sendSMS} name="sendSMS"

                                        color="primary" />}

                                    inputRef={register({
                                        required: false,
                                    })}
                                    onClick={SMSChange}
                                    label="Send SMS"
                                /> */}
                            </Grid>
                        </form>
                        <DialogContent style={{ marginLeft: '-22px' }}>
                            I confirm that the parent has consented for the workshop
                            </DialogContent>



                    </DialogContent>
                    <DialogActions>

                        <Button onClick={handleClose} variant="outlined" size={"small"} color="secondary"
                            endIcon={<CancelOutlinedIcon />}>
                            Cancel
                        </Button>
                        <Button variant="outlined" size={"small"} color="primary"
                            onClick={handleSubmit(onSubmit)} disabled={!submit} endIcon={<SaveOutlinedIcon />} >
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </div>
    );
}

export default FormDialog;
