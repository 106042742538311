import React from "react";
import {
  Typography,
  Grid,
  Paper,
  useTheme,
  makeStyles,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from "@material-ui/core";

import instructions from "./instructions.png";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import sample_test_taking_1 from "./sample_test_taking_1.png";
import sample_test_taking_2 from "./sample_test_taking_2.png";

import dashboard from "./studentDashboard.png";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Star } from "@material-ui/icons";
import { Margin } from "@mui/icons-material";
import medal1 from "../Dashboard/Medal1.png";
import medal2 from "../Dashboard/Medal2.png"
import medal3 from "../Dashboard/Medal3.png"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    overflowX: "hidden",
    backgroundImage: `url(${instructions})`,
  },
  paper: {
    marginTop: "10px",
    justifyContent: "screenRight",
    backgroundSize: "cover",
    backgroundAttachment: "scroll",
    backgroundRepeat: " no-repeat",
    backgroundPosition: "center",
    position: "absolute",
    width: "100%",
    height: "auto",
    backgroundImage: `url(${instructions})`,
    overflowX: "hidden",
  },
  content: {
    fontSize: "18px",
    textAlign: "left",
    color: "black",
    fontWeight: "bold",
  },
  heading: {
    fontSize: theme.typography.pxToRem(25),
    flexBasis: "100%",
    flexShrink: 2,
    textAlign: "left",
    fontWeight: "bold",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  mainHeading: {
    fontWeight: "bold",
    textAlign: "center",
  },
  button: {
    height: "2%",
    width: "auto",
  },
  imageContainer: {
    textAlign: "center",
    width: "90%",
    marginTop: "2%",
  },star:{
   // backgroundColor:"gold"
  }
}));

function Instructions(props) {
  const classes = useStyles();
  let theme = useTheme();
  const history = useHistory();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const back = "Back To Home";
  const [isAGCLogin, setIsAGCLogin] = React.useState(
    props.location.state.examination === "agc" ? true : false
  );
  const heading = "Practice Portal Instructions";
  const [expandedPanel1, setExpandedPanel1] = React.useState(true);
  const [expandedPanel2, setExpandedPanel2] = React.useState(true);
  const [expandedPanel3, setExpandedPanel3] = React.useState(true);
  const [expandedPanel5, setExpandedPanel5] = React.useState(true);
  const [expandedPanel6, setExpandedPanel6] = React.useState(true);
  const [expandedPanel7, setExpandedPanel7] = React.useState(true);
  const [expandedPanel8, setExpandedPanel8] = React.useState(true);

  const cardHeading1 = "How to get Stars";
  const cardHeading2 = "How to get medals";
  const cardHeading3 = isAGCLogin ? "About Test:" : "Just before the Exam:";
  const cardHeading4 = isAGCLogin
    ? "Minimum Browser Requirements:"
    : "Use of Two devices:";
  const cardHeading5 = isAGCLogin ? "" : "About the Test:";
  const cardHeading6 = isAGCLogin ? "" : "Minimum Browser Requirements:";

  return (
    <div className={classes.root}>
      <Grid container>
        <Paper className={classes.paper}>
          <Grid item xs={12} md={12} style={{ padding: "20px" }}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                history.push("/student/portal/dashboard", {
                  examination: props.location.state.examination,
                  value: 1,
                });
              }}
              className={classes.button}
            >
              {back}
            </Button>
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={12}
            justify="center"
            style={{ textAlign: "center" }}
          >
            <Typography variant="h3" className={classes.mainHeading}>
              {heading}
            </Typography>
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={12}
            style={{ padding: "20px", width: "100%" }}
          >
            <Accordion
              style={{ width: "100%" }}
              expanded={expandedPanel1}
              onChange={() => setExpandedPanel1(!expandedPanel1)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              ></AccordionSummary>

              {
                <AccordionDetails>
                  <ul style={{ textAlign: "left" }} className={classes.content}>
                    <li>
                      <Typography>
                      Practice daily to ensure you improve your skill levels.
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                      Every day you can practice a maximum of 2 times.
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                      Click on the Start Test Button to start the practice.
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                      Answers must be typed in the box given.
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                      Once the answer is typed, click on the right arrow to get to the next question.
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                      You can go to previous or next question by clicking on the question numbers at the bottom
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                      	There are section wise timers.
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                      Depending on your level you may have more than one section.
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                      Sections – Abacus, visual and multiplication/division.
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                      You will be taken to the next section once the time gets over for the current section.
                      </Typography>
                    </li>
                  </ul>
                </AccordionDetails>
              }
            </Accordion>
        
            <Accordion
              style={{ width: "100%" }}
              expanded={expandedPanel2}
              onChange={() => setExpandedPanel2(!expandedPanel2)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography className={classes.heading}>
                  {cardHeading1}
                  <Star fontSize="large" style={{ color: "#f4c430" ,marginLeft:smallScreen? "30%":"70%"}}/>
                  <Star fontSize="large" style={{ color: "#f4c430" }}/>
                  <Star fontSize="large" style={{ color: "#f4c430" }}/>
                  <Star fontSize="large" style={{ color: "#f4c430" }}/>
                  <Star fontSize="large" style={{ color: "#f4c430" }}/>
                </Typography>
              </AccordionSummary>
             
                <AccordionDetails>
                <ul>
                        {" "}
                        <li>
                          <Typography>
                             Practice  3 days (once or twice a day)  a week, get three stars.
                          </Typography>
                        </li>
                        <li>
                          <Typography>
                             Practice 4 days  a week, get four stars.
                          </Typography>
                        </li>
                        <li>
                          <Typography>
                            Practice 5 days or more in a week, get five stars.
                          </Typography>
                        </li>
                      </ul>
                    
                  
                </AccordionDetails>
             
           
            </Accordion>
            <Accordion
              style={{ width: "100%" }}
              expanded={expandedPanel2}
              onChange={() => setExpandedPanel2(!expandedPanel2)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography className={classes.heading}>
                  {cardHeading2}
                  <img src={medal3 } style={{ marginLeft:smallScreen? "15%":"63%",height:smallScreen?"7vh":"10vh"}}/>
                  <img src={medal2}  style={{ marginLeft:"1%",height:smallScreen?"8vh":"11vh"}}/>
                  <img src={medal1}  style={{ marginLeft:"1%",height:smallScreen?"10vh":"12vh"}}/>
                </Typography>
              </AccordionSummary>
             
                <AccordionDetails>
                <ul>
                        {" "}
                        <li>
                          <Typography>
                             Get 3 stars or more for 2 weeks in a month, Win a Bronze Medal.
                          </Typography>
                        </li>
                        <li>
                          <Typography>
                             Get 3 stars or more for 3 weeks in a month, Win a Silver Medal.
                          </Typography>
                        </li>
                        <li>
                          <Typography>
                             Get 3 stars or more in all 4 weeks in a month, Win a Gold Medal.
                          </Typography>
                        </li>
                      </ul>
                    
                  
                </AccordionDetails>
             
           
            </Accordion>
          </Grid>
          <Grid item xs={12} md={12} style={{ padding: "20px" }}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                history.push("/student/portal/dashboard", {
                  examination: props.location.state.examination,
                  value: 1,
                });
              }}
              className={classes.button}
            >
              {back}
            </Button>
          </Grid>
        </Paper>
      </Grid>
    </div>
  );
}

export default Instructions;
